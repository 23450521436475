
import { AdmissionFormData, FileUploadField } from "@/types/admissionForm";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { handleDatabaseError } from "@/utils/supabaseErrorHandler";

export async function submitAdmissionForm(values: AdmissionFormData): Promise<boolean> {
  try {
    // Upload files to Supabase Storage
    const uploadResults = await Promise.all([
      uploadFile(values.birthCertificate.file, 'birth-certificates'),
      uploadFile(values.aadhaarCard.file, 'aadhaar-cards'),
      values.markSheet ? uploadFile(values.markSheet.file, 'mark-sheets') : Promise.resolve(null)
    ]);

    if (!uploadResults[0] || !uploadResults[1]) {
      console.error("Document upload failed:", uploadResults);
      throw new Error("Failed to upload required documents");
    }

    // Create the application record in the database
    const { error } = await supabase.from('admission_applications').insert({
      student_name: values.studentName,
      dob: values.dob,
      gender: values.gender,
      applying_for_class: values.applyingForClass,
      father_name: values.fatherName,
      father_occupation: values.fatherOccupation || null,
      mother_name: values.motherName,
      mother_occupation: values.motherOccupation || null,
      address: values.address,
      phone_number: values.phoneNumber,
      email: values.email,
      previous_school: values.previousSchool || null,
      previous_class: values.previousClass || null,
      additional_info: values.additionalInfo || null,
      birth_certificate_url: uploadResults[0],
      aadhaar_card_url: uploadResults[1],
      mark_sheet_url: uploadResults[2]
    });

    if (error) {
      handleDatabaseError(error, "Error submitting admission form");
      return false;
    }
    
    // Success - update UI
    toast.success("Application Submitted", {
      description: "Your application has been submitted successfully. We will contact you soon.",
    });
    
    return true;
  } catch (error: any) {
    console.error("Error submitting form:", error);
    toast.error("Submission Failed", {
      description: error.message || "There was an error submitting your application. Please try again.",
    });
    return false;
  }
}

async function uploadFile(file: File, folder: string): Promise<string | null> {
  try {
    // Create a unique file name using timestamp and random number
    const fileExt = file.name.split('.').pop();
    const uniqueId = `${Date.now()}-${Math.round(Math.random() * 10000000)}`;
    const fileName = `${uniqueId}.${fileExt}`;
    const filePath = `${folder}/${fileName}`;
    
    // Upload file to Supabase Storage
    const { error, data } = await supabase.storage
      .from('admission-documents')
      .upload(filePath, file);
    
    if (error) {
      console.error(`Error uploading ${folder} file:`, error);
      throw error;
    }
    
    // Return the path to the uploaded file
    return filePath;
  } catch (error) {
    console.error(`Error in uploadFile (${folder}):`, error);
    return null;
  }
}
