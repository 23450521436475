
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useAuth } from './AuthContext';
import { toast } from 'sonner';
import { Database } from '@/integrations/supabase/types';

type Announcement = Database['public']['Tables']['announcements']['Row'] & {
  creator_name?: string;
};

interface AnnouncementsContextType {
  announcements: Announcement[];
  loading: boolean;
  error: string | null;
  createAnnouncement: (title: string, content: string) => Promise<void>;
  updateAnnouncement: (id: string, title: string, content: string) => Promise<void>;
  deleteAnnouncement: (id: string) => Promise<void>;
  refreshAnnouncements: () => Promise<void>;
}

const AnnouncementsContext = createContext<AnnouncementsContextType | undefined>(undefined);

export const AnnouncementsProvider = ({ children }: { children: ReactNode }) => {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user, isAuthenticated } = useAuth();

  const fetchAnnouncements = async () => {
    try {
      setLoading(true);
      setError(null);

      if (!isAuthenticated) {
        setAnnouncements([]);
        return;
      }

      const { data, error } = await supabase
        .from('announcements')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) {
        throw error;
      }

      // Process the data
      setAnnouncements(data || []);
    } catch (err: any) {
      console.error('Error fetching announcements:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchAnnouncements();
    } else {
      setAnnouncements([]);
    }
  }, [isAuthenticated]);

  const createAnnouncement = async (title: string, content: string) => {
    try {
      setLoading(true);
      setError(null);

      if (!user) {
        throw new Error('You must be logged in to create an announcement');
      }

      const { data, error } = await supabase
        .from('announcements')
        .insert([
          {
            title,
            content,
            created_by: user.id,
          },
        ])
        .select();

      if (error) {
        throw error;
      }

      toast.success('Announcement created successfully');
      await fetchAnnouncements();
    } catch (err: any) {
      console.error('Error creating announcement:', err);
      setError(err.message);
      toast.error(`Failed to create announcement: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const updateAnnouncement = async (id: string, title: string, content: string) => {
    try {
      setLoading(true);
      setError(null);

      if (!user) {
        throw new Error('You must be logged in to update an announcement');
      }

      const { data, error } = await supabase
        .from('announcements')
        .update({
          title,
          content,
          updated_at: new Date().toISOString(),
        })
        .eq('id', id)
        .select();

      if (error) {
        throw error;
      }

      toast.success('Announcement updated successfully');
      await fetchAnnouncements();
    } catch (err: any) {
      console.error('Error updating announcement:', err);
      setError(err.message);
      toast.error(`Failed to update announcement: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const deleteAnnouncement = async (id: string) => {
    try {
      setLoading(true);
      setError(null);

      if (!user) {
        throw new Error('You must be logged in to delete an announcement');
      }

      const { error } = await supabase
        .from('announcements')
        .delete()
        .eq('id', id);

      if (error) {
        throw error;
      }

      toast.success('Announcement deleted successfully');
      setAnnouncements(announcements.filter((announcement) => announcement.id !== id));
    } catch (err: any) {
      console.error('Error deleting announcement:', err);
      setError(err.message);
      toast.error(`Failed to delete announcement: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const refreshAnnouncements = async () => {
    await fetchAnnouncements();
  };

  return (
    <AnnouncementsContext.Provider
      value={{
        announcements,
        loading,
        error,
        createAnnouncement,
        updateAnnouncement,
        deleteAnnouncement,
        refreshAnnouncements,
      }}
    >
      {children}
    </AnnouncementsContext.Provider>
  );
};

export const useAnnouncements = () => {
  const context = useContext(AnnouncementsContext);
  if (context === undefined) {
    throw new Error('useAnnouncements must be used within an AnnouncementsProvider');
  }
  return context;
};
