
// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://udoeyqorisjketlmedrk.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InVkb2V5cW9yaXNqa2V0bG1lZHJrIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDExODczNzcsImV4cCI6MjA1Njc2MzM3N30.IWX4dYI0zjURcECZV8-35tI35V-bSpFPr5cJpzvUenU";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);

// Helper function to get public URL for an image in the storage bucket
export const getPublicImageUrl = (imagePath: string) => {
  const { data } = supabase.storage.from('website-images').getPublicUrl(imagePath);
  console.log(`Generated URL for ${imagePath}: ${data.publicUrl}`);
  return data.publicUrl;
};
