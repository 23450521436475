
import React from 'react';
import { Layout } from '@/components/layout/Layout';
import { Card, CardContent } from '@/components/ui-components/Card';
import { FunButton } from '@/components/ui-components/FunButton';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const ChairmansMessage = () => {
  return (
    <Layout>
      <div className="container px-4 py-12 max-w-4xl mx-auto">
        <FunButton 
          component={Link}
          to="/" 
          color="secondary"
          className="mb-8"
          leftIcon={<ArrowLeft className="h-4 w-4" />}
        >
          Back to Home
        </FunButton>
        
        <Card className="overflow-hidden border-secondary/20 border-2">
          <div className="bg-secondary/10 p-6 flex flex-col items-center">
            <h1 className="text-3xl md:text-4xl font-display font-bold text-secondary mb-6">
              Chairman's Message
            </h1>
            <div className="rounded-full overflow-hidden border-4 border-secondary/20 w-40 h-40 mx-auto mb-4">
              <img 
                src="/lovable-uploads/fc6297f4-cabe-4343-8e56-e612adb74466.png" 
                alt="Chairman Sobha Sreekumar" 
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          
          <CardContent className="p-6 md:p-8 prose prose-lg dark:prose-invert max-w-none">
            <p>
              As chairman, St Thomas, higher secondary school, Dimapur, Nagaland, I extend a warm welcome
              to all our website visitors. St. Thomas Higher secondary School is a private institution recognised
              by the Nagaland Board of school education established in 2002 under St Thomas charitable trust.
            </p>

            <p>
              It has marked amongst the finest centres in Nagaland with a strict administration, discipline, and
              academic results. The school has made tremendous achievements during the last 23 years of
              tireless efforts by a team of eminent educationist, who are Pioneers in spreading modern
              Montessori education in Nagaland.
            </p>

            <p>
              It is situated in Nagaon colony about 2 km from Dimapur town where the students receive the
              benefits of both urban and rural Nagaland. We are committed in promoting vibrant, learning,
              environment, where students thrive academically, socially and spiritually, preparing them to face
              the challenging world with self-confidence
            </p>

            <p>
              With the holistic approach to education, keeping in mind both curricular and co-curricular
              activities, our school aims for the overall development of students. The school is dedicated to
              prepare conscientious, responsible and creative citizens where education is an in "Aid to life".
            </p>

            <p>
              The integrated development of the children is reflected in the school emblem with moto "Light to
              Life". We encourage active participation from parents and society, recognising that a strong bond
              is crucial in shaping the future of our students and building a better tomorrow.
            </p>
            
            <div className="text-right mt-8">
              <p className="font-bold text-xl mb-0">Sobha Sreekumar</p>
              <p className="text-muted-foreground">Chairman</p>
            </div>
          </CardContent>
        </Card>
      </div>
    </Layout>
  );
};

export default ChairmansMessage;
