
import React, { memo } from 'react';
import { Badge } from '@/components/ui-components/Badge';
import { FunButton } from '@/components/ui-components/FunButton';
import { ImageCarousel } from '@/components/ui-components/ImageCarousel';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

interface HeroSectionProps {
  images: {
    src: string;
    alt: string;
    caption?: string;
  }[];
}

// Using memo to prevent unnecessary re-renders
export const HeroSection = memo(function HeroSection({ images }: HeroSectionProps) {
  return (
    <section className="py-16 md:py-20 bg-gradient-to-r from-blue-50 via-green-50 to-yellow-50 dark:from-slate-900 dark:via-blue-900 dark:to-purple-900 relative overflow-hidden">
      <div className="absolute inset-0 opacity-10">
        <div className="absolute -top-10 -left-10 w-40 h-40 rounded-full bg-primary"></div>
        <div className="absolute top-20 right-10 w-20 h-20 rounded-full bg-secondary"></div>
        <div className="absolute bottom-10 left-1/4 w-30 h-30 rounded-full bg-accent"></div>
        <div className="absolute -bottom-10 right-1/3 w-40 h-40 rounded-full bg-green-300"></div>
      </div>
      
      <div className="container px-4 md:px-6 relative z-10">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          <motion.div 
            className="flex flex-col items-start text-left space-y-6 max-w-xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <div className="inline-block mb-2">
              <Badge className="bg-primary text-white font-display px-4 py-1">Welcome to</Badge>
            </div>
            <h1 className="text-4xl md:text-5xl font-display tracking-tighter leading-tight text-gradient">
              St. Thomas Higher Secondary School
            </h1>
            <p className="text-lg text-muted-foreground max-w-2xl">
              A colorful world of learning where every child grows, explores, and shines!
              Join our happy school family today.
            </p>
            <div className="flex flex-col sm:flex-row gap-3 pt-2">
              <FunButton 
                component={Link}
                to="/admission-form" 
                color="primary" 
                size="lg" 
                animationEffect="pulse"
                className="px-8"
              >
                Apply Now
              </FunButton>
              <FunButton 
                component={Link}
                to="/about-us" 
                color="secondary"
                size="lg"
              >
                Learn More
              </FunButton>
            </div>
          </motion.div>
          
          <motion.div 
            className="w-full lg:w-1/2 h-80 lg:h-96"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <ImageCarousel 
              images={images} 
              className="w-full h-full rounded-3xl border-4 border-primary shadow-xl"
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
});
