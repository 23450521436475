
import React, { memo } from 'react';
import { motion } from 'framer-motion';

interface FeaturedImageGridProps {
  images: {
    src: string;
    alt: string;
    caption?: string;
  }[];
  className?: string;
}

// Using memo to prevent unnecessary re-renders
export const FeaturedImageGrid = memo(function FeaturedImageGrid({ images, className = "" }: FeaturedImageGridProps) {
  return (
    <div className={`grid grid-cols-1 md:grid-cols-2 gap-6 ${className}`}>
      {images.map((image, index) => (
        <motion.div
          key={index}
          className="overflow-hidden rounded-2xl border-4 border-primary shadow-lg hover:shadow-xl transition-all duration-300 relative"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ 
            duration: 0.5, 
            delay: index * 0.1,
            ease: "easeOut"
          }}
          whileHover={{ scale: 1.02 }}
        >
          <img
            src={image.src}
            alt={image.alt}
            className="w-full h-64 object-cover"
            loading={index < 2 ? "eager" : "lazy"}
          />
          {image.caption && (
            <div className="absolute bottom-0 left-0 right-0 bg-primary text-white p-3 font-display">
              {image.caption}
            </div>
          )}
        </motion.div>
      ))}
    </div>
  );
});
