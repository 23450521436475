
import React from 'react';
import { Layout } from '@/components/layout/Layout';
import { 
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { Download, BookOpen } from 'lucide-react';
import { useToast } from "@/components/ui/use-toast";

const Syllabus = () => {
  const { toast } = useToast();

  // Mock data for syllabi
  const syllabusData = [
    { id: 1, serialNumber: "1.", className: "I" },
    { id: 2, serialNumber: "2.", className: "II" },
    { id: 3, serialNumber: "3.", className: "III" },
    { id: 4, serialNumber: "4.", className: "IV" },
    { id: 5, serialNumber: "5.", className: "V" },
    { id: 6, serialNumber: "6.", className: "VI" },
    { id: 7, serialNumber: "7.", className: "VII" },
    { id: 8, serialNumber: "8.", className: "VIII" },
    { id: 9, serialNumber: "9.", className: "IX" },
    { id: 10, serialNumber: "10.", className: "X" },
  ];

  const handleDownload = (className: string) => {
    // In a real implementation, this would download the actual syllabus file
    toast({
      title: "Download started",
      description: `Syllabus for Class ${className} is being downloaded`,
    });
  };

  return (
    <Layout>
      <div className="container py-8 md:py-12 animate-fade-in">
        <div className="flex flex-col items-center mb-6 md:mb-10">
          <div className="flex items-center gap-2 mb-4">
            <BookOpen className="h-8 w-8 text-primary opacity-80" />
            <h1 className="text-3xl font-bold tracking-tight">SYLLABUS</h1>
          </div>
          
          <p className="text-muted-foreground text-center max-w-lg mb-8">
            Download the latest curriculum and syllabus materials for each class. 
            These documents contain comprehensive learning objectives and course content.
          </p>
          
          <div className="w-full max-w-3xl bg-white dark:bg-slate-800 rounded-lg shadow-sm border overflow-hidden transition-all duration-300 hover:shadow-md">
            <div className="overflow-x-auto">
              <Table>
                <TableHeader className="bg-slate-50 dark:bg-slate-800/80">
                  <TableRow>
                    <TableHead className="w-[100px] font-medium">SL</TableHead>
                    <TableHead className="font-medium">Class</TableHead>
                    <TableHead className="text-right font-medium">Action</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {syllabusData.map((item, index) => (
                    <TableRow 
                      key={item.id} 
                      className={`
                        transition-colors hover:bg-slate-50 dark:hover:bg-slate-800/60
                        ${index % 2 === 0 ? 'bg-white dark:bg-slate-900/20' : 'bg-slate-50/50 dark:bg-slate-900/40'}
                      `}
                    >
                      <TableCell className="font-medium">{item.serialNumber}</TableCell>
                      <TableCell>Class {item.className}</TableCell>
                      <TableCell className="text-right">
                        <Button 
                          variant="default" 
                          onClick={() => handleDownload(item.className)}
                          className="transition-all duration-200 shadow-sm hover:shadow-md hover:translate-y-[-2px] active:translate-y-0"
                        >
                          <Download className="mr-2 h-4 w-4" />
                          Download Syllabus
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
          
          <div className="mt-8 text-sm text-muted-foreground text-center max-w-lg">
            <p>Having trouble downloading? Contact the academic office at <span className="text-primary">academic@sthss.edu</span> for assistance.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Syllabus;
