
import React from 'react';
import { format } from 'date-fns';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import { AspectRatio } from '@/components/ui/aspect-ratio';
import { Button } from '@/components/ui/button';
import { useNews } from '@/context/NewsContext';

export const NewsSection = () => {
  const { newsItems, loading, error } = useNews();
  
  // Get latest news (limit to 3)
  const latestNews = newsItems.slice(0, 3);
  
  if (loading) {
    return (
      <section className="py-16 bg-white">
        <div className="container px-4 mx-auto">
          <h2 className="mb-8 text-3xl font-bold text-center">Latest News</h2>
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {[1, 2, 3].map((i) => (
              <Card key={i} className="overflow-hidden">
                <div className="w-full">
                  <AspectRatio ratio={16 / 9}>
                    <Skeleton className="h-full w-full" />
                  </AspectRatio>
                </div>
                <CardHeader className="pb-2">
                  <Skeleton className="h-6 w-2/3 mb-2" />
                  <Skeleton className="h-4 w-full" />
                </CardHeader>
                <CardContent>
                  <Skeleton className="h-4 w-full mb-2" />
                  <Skeleton className="h-4 w-full mb-2" />
                  <Skeleton className="h-4 w-2/3" />
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </section>
    );
  }
  
  if (error) {
    return (
      <section className="py-16 bg-white">
        <div className="container px-4 mx-auto">
          <h2 className="mb-8 text-3xl font-bold text-center">Latest News</h2>
          <div className="p-4 text-sm text-red-800 bg-red-100 rounded-md">
            <p>Error loading news: {error}</p>
          </div>
        </div>
      </section>
    );
  }
  
  if (latestNews.length === 0) {
    return (
      <section className="py-16 bg-white">
        <div className="container px-4 mx-auto">
          <h2 className="mb-8 text-3xl font-bold text-center">Latest News</h2>
          <p className="text-center text-gray-600">No news articles available at this time. Check back soon!</p>
        </div>
      </section>
    );
  }

  return (
    <section className="py-16 bg-white">
      <div className="container px-4 mx-auto">
        <h2 className="mb-8 text-3xl font-bold text-center">Latest News</h2>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {latestNews.map((item) => (
            <Card key={item.id} className="overflow-hidden h-full flex flex-col transition-all hover:shadow-lg">
              {item.image_url && (
                <div className="w-full">
                  <AspectRatio ratio={16 / 9}>
                    <img 
                      src={item.image_url} 
                      alt={item.title}
                      className="object-cover w-full h-full"
                      onError={(e) => {
                        e.currentTarget.src = "/placeholder.svg";
                      }}
                    />
                  </AspectRatio>
                </div>
              )}
              <CardHeader className="pb-2">
                <CardTitle className="text-xl">{item.title}</CardTitle>
                <CardDescription>
                  {format(new Date(item.published_at), 'MMMM dd, yyyy')}
                </CardDescription>
              </CardHeader>
              <CardContent className="flex-grow">
                <p className="text-gray-700 line-clamp-3">
                  {item.content.replace(/<[^>]*>/g, '')}
                </p>
              </CardContent>
              <CardFooter>
                <Button variant="outline" size="sm">Read More</Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};
