import React, { ReactNode, ElementType } from 'react';
import { cn } from '@/lib/utils';

export interface FunButtonProps {
  children: ReactNode;
  className?: string;
  color?: 'primary' | 'secondary' | 'accent';
  size?: 'sm' | 'md' | 'lg';
  onClick?: () => void;
  href?: string;
  to?: string;
  component?: ElementType;
  animationEffect?: 'wiggle' | 'bounce' | 'pulse' | 'none';
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

export function FunButton({
  children,
  className,
  color = 'primary',
  size = 'md',
  onClick,
  href,
  to,
  component: Component,
  animationEffect = 'none',
  leftIcon,
  rightIcon,
}: FunButtonProps) {
  const baseStyles = "font-display rounded-full shadow-playful transition-all duration-300 transform hover:scale-105 active:scale-95 text-white flex items-center justify-center gap-2";
  
  const colorStyles = {
    primary: "bg-primary hover:bg-primary/90",
    secondary: "bg-secondary hover:bg-secondary/90",
    accent: "bg-accent hover:bg-accent/90",
  };
  
  const sizeStyles = {
    sm: "py-1 px-4 text-sm",
    md: "py-2 px-6 text-base",
    lg: "py-3 px-8 text-lg",
  };
  
  const animationStyles = {
    wiggle: "wiggle",
    bounce: "bounce",
    pulse: "pulse",
    none: "",
  };
  
  const buttonStyles = cn(
    baseStyles,
    colorStyles[color],
    sizeStyles[size],
    animationStyles[animationEffect],
    className
  );

  const buttonContent = (
    <>
      {leftIcon}
      {children}
      {rightIcon}
    </>
  );

  if (Component) {
    return (
      <Component to={to} href={href} className={buttonStyles} onClick={onClick}>
        {buttonContent}
      </Component>
    );
  }
  
  if (href) {
    return (
      <a href={href} className={buttonStyles} onClick={onClick}>
        {buttonContent}
      </a>
    );
  }
  
  return (
    <button onClick={onClick} className={buttonStyles}>
      {buttonContent}
    </button>
  );
}
