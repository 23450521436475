
import React, { useState } from 'react';
import { Layout } from '@/components/layout/Layout';
import { FileText, Upload, CheckCircle, BookOpen, School, Users, Star, Sparkles } from 'lucide-react';
import { Separator } from '@/components/ui/separator';
import { CollapsibleSection } from '@/components/admissions/CollapsibleSection';
import { EligibilityCriteria } from '@/components/admissions/EligibilityCriteria';
import { RequiredDocuments } from '@/components/admissions/RequiredDocuments';
import { AdmissionProcess } from '@/components/admissions/AdmissionProcess';
import { AdmissionCTA } from '@/components/admissions/AdmissionCTA';
import { ImageCarousel } from '@/components/ui-components/ImageCarousel';
import { schoolImages } from '@/components/home/data/schoolImages';
import * as motion from 'framer-motion';

export default function OnlineAdmissions() {
  const [openSection, setOpenSection] = useState<string | null>("eligibility");

  const toggleSection = (section: string) => {
    setOpenSection(openSection === section ? null : section);
  };

  // Create a subset of images for this page to ensure proper display
  const admissionImages = schoolImages.slice(0, 3);

  return (
    <Layout>
      <div className="container px-4 py-12 md:py-16 mx-auto animate-fade-in">
        <div className="max-w-4xl mx-auto">
          <div 
            className="text-center mb-12"
          >
            <h1 className="text-4xl md:text-5xl font-display font-bold mb-4 text-gradient relative inline-block">
              Join Our School!
              <span className="absolute -top-6 -right-6 text-secondary wiggle">
                <Star className="h-8 w-8" />
              </span>
            </h1>
            <p className="text-muted-foreground text-lg max-w-2xl mx-auto">
              Begin your exciting journey at St. Thomas Higher Secondary School and discover a world of fun and learning!
            </p>
          </div>
          
          <div 
            className="mb-12"
          >
            <ImageCarousel 
              images={admissionImages} 
              className="w-full h-72 md:h-96 rounded-3xl border-4 border-primary shadow-xl"
            />
          </div>

          <div className="space-y-8">
            <div className="space-y-6">
              <CollapsibleSection 
                title="Who Can Join Our School?" 
                icon={Users} 
                isOpen={openSection === "eligibility"} 
                onToggle={() => toggleSection("eligibility")}
                id="eligibility"
              >
                <EligibilityCriteria />
              </CollapsibleSection>

              <CollapsibleSection 
                title="Documents You'll Need" 
                icon={FileText} 
                isOpen={openSection === "documents"} 
                onToggle={() => toggleSection("documents")}
                id="documents"
              >
                <RequiredDocuments />
              </CollapsibleSection>

              <CollapsibleSection 
                title="How to Join Our School" 
                icon={School} 
                isOpen={openSection === "process"} 
                onToggle={() => toggleSection("process")}
                id="process"
              >
                <AdmissionProcess />
              </CollapsibleSection>
            </div>

            <Separator className="my-10" />

            <AdmissionCTA />
          </div>
        </div>
      </div>
    </Layout>
  );
}
