
import React, { memo } from 'react';
import { FunButton } from '@/components/ui-components/FunButton';

// Using memo to prevent unnecessary re-renders
export const CTASection = memo(function CTASection() {
  return (
    <section className="py-16 md:py-24 plant-activity-bg">
      <div className="container px-4 md:px-6">
        <div className="flex flex-col md:flex-row items-center justify-between gap-8 bg-white/80 dark:bg-slate-900/80 backdrop-blur-md rounded-3xl p-8 border-4 border-primary">
          <div className="space-y-4 max-w-xl">
            <h2 className="text-3xl md:text-4xl font-display tracking-tighter text-primary">
              Ready for a Colorful Learning Adventure?
            </h2>
            <p className="text-muted-foreground md:text-lg">
              Join St. Thomas Higher Secondary School for a fun-filled educational experience!
            </p>
          </div>
          <FunButton 
            href="/admission-form" 
            color="primary" 
            size="lg" 
            animationEffect="bounce"
            className="px-8"
          >
            Apply Now!
          </FunButton>
        </div>
      </div>
    </section>
  );
});
