
import React from 'react';
import { Layout } from '@/components/layout/Layout';
import { BookOpen, GraduationCap, Users, Award, MapPin, Mail, Phone } from 'lucide-react';
import { Separator } from '@/components/ui/separator';

export default function AboutUs() {
  return (
    <Layout>
      <div className="container px-4 py-12 md:py-16 mx-auto">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-10">
            <h1 className="text-3xl md:text-4xl font-bold mb-6">About St. Thomas Higher Secondary School</h1>
            
            <p className="text-xl text-muted-foreground italic font-medium">
              "Light to Life" - Empowering Students Through Knowledge and Character
            </p>
          </div>
          
          <div className="space-y-10">
            <section>
              <div className="flex items-center gap-3 mb-4">
                <BookOpen className="h-6 w-6 text-primary" />
                <h2 className="text-2xl font-semibold">Our History</h2>
              </div>
              <p className="text-muted-foreground mb-4">
                Founded in 2000, St. Thomas Higher Secondary School has a rich heritage of academic excellence and character development. 
                What began as a small institution with just three classrooms has grown into one of the most respected educational institutions 
                in the region.
              </p>
              <p className="text-muted-foreground mb-4">
                Through decades of dedicated service, we have nurtured thousands of students who have gone on to make significant 
                contributions to society in various fields. Our commitment to educational excellence has remained unwavering through the years,
                as we continuously adapt our methods to meet the evolving needs of students while preserving our core values and traditions.
              </p>
              <p className="text-muted-foreground">
                St. Thomas Higher Secondary School is proudly affiliated with the Nagaland Board of School Education and operates under 
                the guidance of the St. Thomas Charitable Trust, ensuring that we maintain the highest standards of education and ethical 
                values in all our endeavors.
              </p>
            </section>
            
            <Separator />
            
            <section>
              <div className="flex items-center gap-3 mb-4">
                <GraduationCap className="h-6 w-6 text-primary" />
                <h2 className="text-2xl font-semibold">Our Mission</h2>
              </div>
              <p className="text-muted-foreground mb-4">
                At St. Thomas Higher Secondary School, our mission is to provide a holistic education that nurtures both the intellect and character of our students. 
                We strive to create an environment that encourages academic excellence, critical thinking, creativity, and moral values.
              </p>
              <p className="text-muted-foreground">
                We are committed to preparing our students not just for examinations, but for life itself. Through a balanced approach to education, 
                we aim to develop well-rounded individuals who are equipped to face the challenges of the modern world while remaining grounded in 
                timeless values of integrity, respect, and service.
              </p>
            </section>
            
            <Separator />
            
            <section>
              <div className="flex items-center gap-3 mb-4">
                <Award className="h-6 w-6 text-primary" />
                <h2 className="text-2xl font-semibold">Our Vision</h2>
              </div>
              <p className="text-muted-foreground mb-4">
                Our vision is to be a leading educational institution that sets new standards of excellence in academic and co-curricular activities.
                We envision our students as future leaders and change-makers who will contribute positively to society and uphold the values of
                compassion, integrity, and excellence.
              </p>
              <p className="text-muted-foreground">
                We aspire to create a learning environment where innovation meets tradition, and where students are encouraged to explore,
                question, and discover their unique potential. Through continuous improvement and adaptation, we aim to remain at the forefront
                of educational practices while maintaining our commitment to holistic development.
              </p>
            </section>
            
            <Separator />
            
            <section>
              <div className="flex items-center gap-3 mb-4">
                <Users className="h-6 w-6 text-primary" />
                <h2 className="text-2xl font-semibold">Our Team</h2>
              </div>
              <p className="text-muted-foreground mb-6">
                Our school is led by a team of dedicated educators with extensive experience in the field of education. Our faculty members are 
                not just teachers, but mentors who guide students through their educational journey with care and dedication.
              </p>
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="text-center">
                  <div className="w-24 h-24 rounded-full bg-gray-200 mx-auto mb-3 flex items-center justify-center overflow-hidden">
                    <span className="text-3xl font-semibold text-gray-500">FD</span>
                  </div>
                  <h3 className="font-medium">Fr. David Thomas</h3>
                  <p className="text-sm text-muted-foreground">Principal</p>
                </div>
                
                <div className="text-center">
                  <div className="w-24 h-24 rounded-full bg-gray-200 mx-auto mb-3 flex items-center justify-center overflow-hidden">
                    <span className="text-3xl font-semibold text-gray-500">SM</span>
                  </div>
                  <h3 className="font-medium">Dr. Sarah Matthews</h3>
                  <p className="text-sm text-muted-foreground">Vice Principal</p>
                </div>
                
                <div className="text-center">
                  <div className="w-24 h-24 rounded-full bg-gray-200 mx-auto mb-3 flex items-center justify-center overflow-hidden">
                    <span className="text-3xl font-semibold text-gray-500">RK</span>
                  </div>
                  <h3 className="font-medium">Prof. Rajiv Kumar</h3>
                  <p className="text-sm text-muted-foreground">Academic Director</p>
                </div>
              </div>
            </section>
            
            <Separator />
            
            <section>
              <div className="flex items-center gap-3 mb-4">
                <MapPin className="h-6 w-6 text-primary" />
                <h2 className="text-2xl font-semibold">Facilities</h2>
              </div>
              <p className="text-muted-foreground mb-4">
                Our campus is equipped with modern facilities to enhance the learning experience of our students. From well-equipped laboratories
                to spacious classrooms, we provide an environment conducive to academic growth and personal development.
              </p>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
                <div className="p-4 border rounded-md">
                  <h3 className="font-medium mb-2">Academic Facilities</h3>
                  <ul className="list-disc list-inside text-muted-foreground space-y-1">
                    <li>Modern computer laboratories</li>
                    <li>Well-equipped science laboratories</li>
                    <li>Comprehensive library with digital resources</li>
                    <li>Smart classrooms with interactive technology</li>
                  </ul>
                </div>
                
                <div className="p-4 border rounded-md">
                  <h3 className="font-medium mb-2">Sports Facilities</h3>
                  <ul className="list-disc list-inside text-muted-foreground space-y-1">
                    <li>Multi-purpose sports ground</li>
                    <li>Indoor sports complex</li>
                    <li>Basketball and volleyball courts</li>
                    <li>Training equipment for various sports</li>
                  </ul>
                </div>
              </div>
            </section>
          </div>
          
          <div className="mt-12 p-6 bg-muted rounded-lg">
            <h2 className="text-xl font-semibold mb-4">Contact Us</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-3">
                <div className="flex items-start gap-3">
                  <MapPin className="h-5 w-5 text-primary mt-0.5" />
                  <div>
                    <p className="font-medium">Address</p>
                    <p className="text-muted-foreground">Signal Naga Gaon, Dimapur</p>
                    <p className="text-muted-foreground">PIN code - 797118</p>
                  </div>
                </div>
                
                <div className="flex items-start gap-3">
                  <Mail className="h-5 w-5 text-primary mt-0.5" />
                  <div>
                    <p className="font-medium">Email</p>
                    <a href="mailto:stthomasnaggaon@gmail.com" className="text-muted-foreground hover:text-primary transition-colors">
                      stthomasnaggaon@gmail.com
                    </a>
                  </div>
                </div>
              </div>
              
              <div className="space-y-3">
                <div className="flex items-start gap-3">
                  <Phone className="h-5 w-5 text-primary mt-0.5" />
                  <div>
                    <p className="font-medium">Phone</p>
                    <a href="tel:+919862589089" className="text-muted-foreground hover:text-primary transition-colors">
                      +91 9862589089
                    </a>
                    <br />
                    <a href="tel:+917005570394" className="text-muted-foreground hover:text-primary transition-colors">
                      +91 7005570394
                    </a>
                  </div>
                </div>
                
                <div className="flex items-start gap-3">
                  <GraduationCap className="h-5 w-5 text-primary mt-0.5" />
                  <div>
                    <p className="font-medium">Office Hours</p>
                    <p className="text-muted-foreground">Monday - Friday: 8:00 AM - 4:00 PM</p>
                    <p className="text-muted-foreground">Saturday: 8:00 AM - 12:00 PM</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
