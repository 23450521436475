
import React from 'react';
import { Button } from "@/components/ui/button";
import { Send, Loader2 } from 'lucide-react';

interface SubmitButtonProps {
  isUploading: boolean;
}

export function SubmitButton({ isUploading }: SubmitButtonProps) {
  return (
    <Button 
      type="submit" 
      className="w-full font-display text-lg py-6 rounded-full bg-gradient-to-r from-primary to-accent hover:opacity-90 transition-all duration-300 transform hover:scale-[1.02] active:scale-[0.98] shadow-lg"
      disabled={isUploading}
    >
      {isUploading ? (
        <>
          <Loader2 className="mr-2 h-5 w-5 animate-spin" />
          Submitting Application...
        </>
      ) : (
        <>
          <Send className="mr-2 h-5 w-5" />
          Submit My Application
        </>
      )}
    </Button>
  );
}
