
import React from 'react';
import { Layout } from '@/components/layout/Layout';
import { Card, CardContent } from '@/components/ui-components/Card';
import { FunButton } from '@/components/ui-components/FunButton';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const PrincipalsMessage = () => {
  return (
    <Layout>
      <div className="container px-4 py-12 max-w-4xl mx-auto">
        <FunButton 
          component={Link}
          to="/" 
          color="secondary"
          className="mb-8"
          leftIcon={<ArrowLeft className="h-4 w-4" />}
        >
          Back to Home
        </FunButton>
        
        <Card className="overflow-hidden border-primary/20 border-2">
          <div className="bg-primary/10 p-6 flex flex-col items-center">
            <h1 className="text-3xl md:text-4xl font-display font-bold text-primary mb-6">
              Principal's Message
            </h1>
            <div className="rounded-full overflow-hidden border-4 border-primary/20 w-40 h-40 mx-auto mb-4">
              <img 
                src="/lovable-uploads/887e732a-5304-4a51-a4bb-b72dabc8aa08.png" 
                alt="Principal Aravind Mini" 
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          
          <CardContent className="p-6 md:p-8 prose prose-lg dark:prose-invert max-w-none">
            <blockquote className="text-xl italic font-semibold text-gray-700 dark:text-gray-300 border-l-4 border-primary pl-4">
              "Education is the most powerful weapon which you can use to change the world."
              <footer className="text-sm font-normal mt-1">— Nelson Mandela</footer>
            </blockquote>
            
            <p>
              A significant journey of a thousand miles often begins with a single purposeful step. Today, the
              role of school is not only to pursue academic excellence but also to motivate and empower its
              students to be lifelong learners, critical thinkers, and productive members of an ever-changing
              society. As we stand on the threshold of a new academic session, I extend a hearty and warm
              welcome to all my students, staff and parents. Each academic year is a new height scaled,
              another dream realized with new targets set for the future. Each member of this institution is
              devoted to turning dreams & aspirations into reality through sincerity & perseverance
            </p>

            <p>
              We at St, Thomas Higher Secondary School provide an atmosphere to our students for multi-
              faced development, where children are encouraged to channelize their potential in the pursuit of
              excellence. We always try to maintain the highest quality in academic standards and provide the
              most conducive environment for our student's holistic growth and development. We also strive to
              instill the core values of Respect, Integrity, Compassion and Excellence in our students so they
              can meet the ever-changing challenges. Our dedicated and highly qualified staff stands as
              exemplary role models for our students thereby keeping the ethos of our school shining bright.
            </p>

            <p>
              Our children are our strength and inspiration. Each child is a fountainhead of curiosity, which we
              recognize and respect. We encourage our children to turn their ideas and passions into something
              original and valuable. Deeply ensconced in the tradition of the land, we take pride in remaining
              rooted to our cultural identity while imparting an educational experience that is world-class in
              every respect and which prepares children for global citizenship.
            </p>

            <p>
              As we take firm strides into the future, it will be our lifelong aim to produce leaders who will not
              only be trailblazers in their chosen field but also have the courage to be instruments of change.
            </p>

            <p>
              "Education is a shared commitment between dedicated teachers, motivated students and
              enthusiastic parents with high expectations." We wish to thank all the parents for their faith in
              School. Let's partner together, so that we can see children being successful in whatever path they
              choose to tread.
            </p>

            <p className="text-center font-semibold">
              "With warm wishes and Almighty's blessings".
            </p>
            
            <div className="text-right mt-8">
              <p className="font-bold text-xl mb-0">Aravind Mini</p>
              <p className="text-muted-foreground">Principal</p>
            </div>
          </CardContent>
        </Card>
      </div>
    </Layout>
  );
};

export default PrincipalsMessage;
