
import React from 'react';
import { useLocation } from 'react-router-dom';
import { MapPin, GraduationCap, Mail, Phone } from 'lucide-react';

export function Footer() {
  const currentYear = new Date().getFullYear();
  const location = useLocation();
  
  // Helper function to create safe links that work with or without Router context
  const SafeLink = ({ to, className, children }: { to: string, className?: string, children: React.ReactNode }) => {
    // For internal navigation
    return (
      <a href={to} className={className}>
        {children}
      </a>
    );
  };

  return (
    <footer className="border-t border-border/40 bg-background py-8">
      <div className="container px-4 md:px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="space-y-4">
            <SafeLink to="/" className="flex items-center gap-2">
              <img 
                src="/lovable-uploads/f6ce99c3-7765-4d7e-8dd3-df7d44a04500.png" 
                alt="St. Thomas Higher Secondary School" 
                className="h-12 w-12"
              />
              <div className="flex flex-col">
                <span className="text-xl font-display font-medium">St. Thomas Higher Secondary School</span>
                <span className="text-xs text-muted-foreground italic">"Light To Life"</span>
              </div>
            </SafeLink>
            <p className="text-sm text-muted-foreground">
              Empowering education through digital innovation.
            </p>
            <div className="flex items-start gap-2 pt-1">
              <MapPin className="h-4 w-4 text-muted-foreground mt-0.5 shrink-0" />
              <p className="text-sm text-muted-foreground">
                Naga Gaon Colony, Signal Basti<br />
                Dimapur-797118
              </p>
            </div>
          </div>
          
          <div>
            <h4 className="text-sm font-medium mb-4">Platform</h4>
            <ul className="space-y-3">
              <li>
                <SafeLink to="/" className="text-sm text-muted-foreground hover:text-foreground transition-colors">
                  Home
                </SafeLink>
              </li>
              <li>
                <SafeLink to="/about-us" className="text-sm text-muted-foreground hover:text-foreground transition-colors">
                  About Us
                </SafeLink>
              </li>
              <li>
                <SafeLink to="/about-us/school-details" className="text-sm text-muted-foreground hover:text-foreground transition-colors">
                  School Details
                </SafeLink>
              </li>
              <li>
                <SafeLink to="/notice" className="text-sm text-muted-foreground hover:text-foreground transition-colors">
                  Notice
                </SafeLink>
              </li>
              <li>
                <SafeLink to="/online-admissions" className="text-sm text-muted-foreground hover:text-foreground transition-colors">
                  Online Admissions
                </SafeLink>
              </li>
              <li>
                <SafeLink to="/login" className="text-sm text-muted-foreground hover:text-foreground transition-colors">
                  Login
                </SafeLink>
              </li>
            </ul>
          </div>
          
          <div>
            <h4 className="text-sm font-medium mb-4">Resources</h4>
            <ul className="space-y-3">
              <li>
                <SafeLink to="/syllabus" className="text-sm text-muted-foreground hover:text-foreground transition-colors">
                  Syllabus
                </SafeLink>
              </li>
              <li>
                <SafeLink to="/principals-message" className="text-sm text-muted-foreground hover:text-foreground transition-colors">
                  Principal's Message
                </SafeLink>
              </li>
              <li>
                <SafeLink to="/chairmans-message" className="text-sm text-muted-foreground hover:text-foreground transition-colors">
                  Chairman's Message
                </SafeLink>
              </li>
              <li>
                <SafeLink to="/about-us/our-team" className="text-sm text-muted-foreground hover:text-foreground transition-colors">
                  Our Team
                </SafeLink>
              </li>
            </ul>
          </div>
          
          <div>
            <h4 className="text-sm font-medium mb-4">Contact</h4>
            <ul className="space-y-3">
              <li className="flex items-center gap-2">
                <Mail className="h-4 w-4 text-muted-foreground shrink-0" />
                <a href="mailto:stthomasnagagaon@gmail.com" className="text-sm text-muted-foreground hover:text-foreground transition-colors">
                  stthomasnagagaon@gmail.com
                </a>
              </li>
              <li className="flex items-center gap-2">
                <Phone className="h-4 w-4 text-muted-foreground shrink-0" />
                <a href="tel:+919862589089" className="text-sm text-muted-foreground hover:text-foreground transition-colors">
                  +91 9862589089
                </a>
              </li>
              <li className="flex items-start gap-2">
                <MapPin className="h-4 w-4 text-muted-foreground mt-0.5 shrink-0" />
                <span className="text-sm text-muted-foreground">
                  Naga Gaon Colony, Signal Basti<br />
                  Dimapur-797118
                </span>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="mt-8 border-t border-border pt-6 flex flex-col md:flex-row justify-between items-center gap-4">
          <p className="text-xs text-muted-foreground">
            © {currentYear} St. Thomas Higher Secondary School. All rights reserved.
          </p>
          <div className="flex items-center gap-4">
            <a href="#" className="text-xs text-muted-foreground hover:text-foreground transition-colors">
              Terms
            </a>
            <a href="#" className="text-xs text-muted-foreground hover:text-foreground transition-colors">
              Privacy
            </a>
            <a href="#" className="text-xs text-muted-foreground hover:text-foreground transition-colors">
              Cookies
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
