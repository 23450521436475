
import React from 'react';

export function AdmissionProcess() {
  return (
    <ol className="list-decimal list-inside space-y-3">
      <li>
        <span className="font-medium">Application Submission:</span>
        <p className="pl-6 text-muted-foreground">
          Fill out the online application form and submit it with all required details.
        </p>
      </li>
      <li>
        <span className="font-medium">Document Verification:</span>
        <p className="pl-6 text-muted-foreground">
          Submit all required documents for verification at the school office within 7 days of online submission.
        </p>
      </li>
      <li>
        <span className="font-medium">Entrance Test/Interview:</span>
        <p className="pl-6 text-muted-foreground">
          Students applying for Class 1 and above will be required to take an entrance test and/or attend an interview.
        </p>
      </li>
      <li>
        <span className="font-medium">Selection and Confirmation:</span>
        <p className="pl-6 text-muted-foreground">
          Selected candidates will be notified via email or phone. Admission must be confirmed by paying the required fees within the specified timeframe.
        </p>
      </li>
    </ol>
  );
}
