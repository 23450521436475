
import React from 'react';
import { Bell, User } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/context/AuthContext';
import { Link } from 'react-router-dom';

export function UserMenu() {
  const { user, logout } = useAuth();

  if (user) {
    return (
      <>
        <Link to="/dashboard" className="text-sm font-medium hover:text-primary transition-colors">
          Dashboard
        </Link>
        <Link to="/profile" className="text-sm font-medium hover:text-primary transition-colors">
          Profile
        </Link>
        <div className="relative">
          <Bell className="h-5 w-5 text-muted-foreground hover:text-primary transition-colors cursor-pointer" />
          <span className="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-primary text-[10px] font-medium text-primary-foreground">
            2
          </span>
        </div>
        <Button 
          variant="ghost" 
          className="gap-2"
          onClick={logout}
        >
          <User className="h-4 w-4" />
          Logout
        </Button>
      </>
    );
  }

  return (
    <Button asChild>
      <Link to="/login">
        Login
      </Link>
    </Button>
  );
}
