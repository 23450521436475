
import { z } from "zod";

export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
export const ACCEPTED_FILE_TYPES = ["application/pdf", "image/jpeg", "image/png"];

export const fileSchema = z.object({
  file: z
    .instanceof(File)
    .refine((file) => file.size <= MAX_FILE_SIZE, "File size must be less than 5MB")
    .refine(
      (file) => ACCEPTED_FILE_TYPES.includes(file.type),
      "Only PDF, JPEG, and PNG files are accepted"
    ),
  name: z.string(),
});

export const admissionFormSchema = z.object({
  studentName: z.string().min(2, "Student name must be at least 2 characters."),
  dob: z.string().min(1, "Date of birth is required."),
  gender: z.string().min(1, "Gender is required."),
  applyingForClass: z.string().min(1, "Class is required."),
  fatherName: z.string().min(2, "Father's name must be at least 2 characters."),
  fatherOccupation: z.string().optional(),
  motherName: z.string().min(2, "Mother's name must be at least 2 characters."),
  motherOccupation: z.string().optional(),
  address: z.string().min(5, "Address must be at least 5 characters."),
  phoneNumber: z.string().min(10, "Phone number must be at least 10 digits."),
  email: z.string().email("Invalid email address."),
  previousSchool: z.string().optional(),
  previousClass: z.string().optional(),
  additionalInfo: z.string().optional(),
  birthCertificate: fileSchema,
  aadhaarCard: fileSchema,
  markSheet: fileSchema.optional(),
  termsAccepted: z.literal(true, {
    errorMap: () => ({ message: "You must accept the terms and conditions" }),
  }),
});

export type AdmissionFormData = z.infer<typeof admissionFormSchema>;

export type FileUploadField = 'birthCertificate' | 'aadhaarCard' | 'markSheet';
