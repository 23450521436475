
import { toast } from 'sonner';
import { PostgrestError, AuthError } from '@supabase/supabase-js';

/**
 * Error handler for Supabase database operations
 * @param error The PostgrestError object from Supabase
 * @param context Custom context message for the error
 * @param notifyUser Whether to show a toast notification to the user
 * @returns The error message
 */
export const handleDatabaseError = (
  error: PostgrestError | null, 
  context: string, 
  notifyUser: boolean = true
): string => {
  if (!error) return '';
  
  const message = `${context}: ${error.message}`;
  console.error(message, error);
  
  if (notifyUser) {
    toast.error(message);
  }
  
  return message;
};

/**
 * Error handler for Supabase authentication operations
 * @param error The AuthError object from Supabase
 * @param context Custom context message for the error
 * @param notifyUser Whether to show a toast notification to the user
 * @returns The error message
 */
export const handleAuthError = (
  error: AuthError | Error | null, 
  context: string, 
  notifyUser: boolean = true
): string => {
  if (!error) return '';
  
  const message = `${context}: ${error.message}`;
  console.error(message, error);
  
  if (notifyUser) {
    toast.error(message);
  }
  
  return message;
};
