
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'sonner';
import { supabase } from '@/integrations/supabase/client';
import { Session } from '@supabase/supabase-js';
import { UserProfile, UserRole, AuthContextType } from '@/types/auth';
import { handleAuthError, handleDatabaseError } from '@/utils/supabaseErrorHandler';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserProfile | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  // Function to transform Supabase user data into our UserProfile format
  const transformUserData = async (session: Session | null): Promise<UserProfile | null> => {
    if (!session?.user) return null;
    
    try {
      // Fetch the user's profile from our profiles table
      const { data: profile, error } = await supabase
        .from('profiles')
        .select('role, first_name, last_name, avatar_url')
        .eq('id', session.user.id)
        .single();
      
      if (error) {
        handleDatabaseError(error, 'Error fetching user profile');
        return null;
      }

      if (!profile) {
        console.error('No profile found for user:', session.user.id);
        return null;
      }

      return {
        id: session.user.id,
        email: session.user.email || '',
        name: `${profile.first_name || ''} ${profile.last_name || ''}`.trim() || session.user.email?.split('@')[0] || 'User',
        role: (profile.role as UserRole) || 'student',
        avatar: profile.avatar_url || undefined,
      };
    } catch (error) {
      console.error('Error transforming user data:', error);
      return null;
    }
  };

  // Initialize auth state
  useEffect(() => {
    const initializeAuth = async () => {
      setLoading(true);
      
      try {
        // Set up auth state listener FIRST
        const { data: { subscription } } = supabase.auth.onAuthStateChange(
          async (event, newSession) => {
            console.log('Auth state changed:', event);
            setSession(newSession);
            
            if (newSession) {
              const userProfile = await transformUserData(newSession);
              setUser(userProfile);
            } else {
              setUser(null);
            }
          }
        );

        // THEN check for existing session
        const { data: { session: currentSession } } = await supabase.auth.getSession();
        setSession(currentSession);
        
        if (currentSession) {
          const userProfile = await transformUserData(currentSession);
          setUser(userProfile);
        }

        return () => {
          subscription.unsubscribe();
        };
      } catch (error) {
        console.error('Auth initialization error:', error);
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      setLoading(true);
      
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      
      if (error) {
        throw error;
      }
      
      toast.success('Successfully signed in!');
      
      // Redirect to the page they were trying to access, or dashboard
      const origin = location.state?.from?.pathname || '/dashboard';
      navigate(origin);
    } catch (error: any) {
      handleAuthError(error, 'Login failed');
    } finally {
      setLoading(false);
    }
  };

  const signUp = async (email: string, password: string, role: UserRole, firstName: string, lastName: string) => {
    try {
      setLoading(true);
      
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            role,
            first_name: firstName,
            last_name: lastName,
          },
        },
      });
      
      if (error) {
        throw error;
      }
      
      toast.success('Registration successful! Please check your email to confirm your account.');
    } catch (error: any) {
      handleAuthError(error, 'Registration failed');
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      setUser(null);
      setSession(null);
      toast.info('You have been logged out');
      navigate('/');
    } catch (error: any) {
      handleAuthError(error, 'Error during logout');
    }
  };

  // Check if user is authenticated
  const checkAuth = () => {
    return !!user;
  };

  // Check if user is authenticated and has required role
  const requireAuth = (allowedRoles?: UserRole[]) => {
    if (!user) return false;
    
    if (allowedRoles && allowedRoles.length > 0) {
      return allowedRoles.includes(user.role);
    }
    
    return true;
  };

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        session,
        login, 
        logout, 
        signUp,
        loading,
        isAuthenticated: !!user,
        checkAuth,
        requireAuth
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
