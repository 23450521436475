
import React, { memo } from 'react';
import { FunButton } from '@/components/ui-components/FunButton';
import { Card } from '@/components/ui-components/Card';
import { Book, FileText } from 'lucide-react';
import { Link } from 'react-router-dom';

// Using memo to prevent unnecessary re-renders
export const MessagesSection = memo(function MessagesSection() {
  return (
    <section className="py-16 bg-gradient-to-r from-green-50 to-blue-50 dark:from-slate-900 dark:to-blue-900">
      <div className="container px-4 md:px-6">
        <div className="text-center mb-10">
          <h2 className="text-3xl md:text-4xl font-display font-bold text-primary mb-4">
            Messages from Our Leaders
          </h2>
          <p className="text-muted-foreground max-w-2xl mx-auto">
            Guidance and inspiration from our school leadership
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Principal's Message Card */}
          <Card className="overflow-hidden hover:shadow-lg transition-all duration-300">
            <div className="bg-green-100 dark:bg-green-900/30 p-4 flex items-center gap-2">
              <Book className="h-5 w-5 text-primary" />
              <h3 className="font-semibold text-lg text-primary">Principal&apos;s Desk</h3>
            </div>
            <div className="p-6 flex flex-col items-center">
              <div className="mb-4 rounded-lg overflow-hidden border-4 border-primary/20">
                <img 
                  src="/lovable-uploads/887e732a-5304-4a51-a4bb-b72dabc8aa08.png" 
                  alt="Principal Aravind Mini" 
                  className="w-full h-64 object-contain"
                  loading="lazy"
                />
              </div>
              <div className="mb-4">
                <p className="text-muted-foreground line-clamp-4">
                  "Education is the most powerful weapon which you can use to change the world." - Nelson Mandela
                  <br /><br />
                  A significant journey of a thousand miles often begins with a single purposeful step. Today, the
                  role of school is not only to pursue academic excellence but also to motivate and empower its
                  students to be lifelong learners...
                </p>
              </div>
              <FunButton 
                component={Link}
                to="/principals-message" 
                color="primary"
                animationEffect="pulse"
              >
                Read More
              </FunButton>
            </div>
          </Card>
          
          {/* Chairman's Message Card */}
          <Card className="overflow-hidden hover:shadow-lg transition-all duration-300">
            <div className="bg-blue-100 dark:bg-blue-900/30 p-4 flex items-center gap-2">
              <FileText className="h-5 w-5 text-secondary" />
              <h3 className="font-semibold text-lg text-secondary">Chairman&apos;s Message</h3>
            </div>
            <div className="p-6 flex flex-col items-center">
              <div className="mb-4 rounded-lg overflow-hidden border-4 border-secondary/20">
                <img 
                  src="/lovable-uploads/fc6297f4-cabe-4343-8e56-e612adb74466.png" 
                  alt="Chairman Sobha Sreekumar" 
                  className="w-full h-64 object-contain"
                  loading="lazy"
                />
              </div>
              <div className="mb-4">
                <p className="text-muted-foreground line-clamp-4">
                  As chairman, St Thomas, higher secondary school, Dimapur, Nagaland, I extend a warm welcome
                  to all our website visitors. St. Thomas Higher secondary School is a private institution recognised
                  by the Nagaland Board of school education established in 2002 under St Thomas charitable trust...
                </p>
              </div>
              <FunButton 
                component={Link}
                to="/chairmans-message" 
                color="secondary"
                animationEffect="pulse"
              >
                Read More
              </FunButton>
            </div>
          </Card>
        </div>
      </div>
    </section>
  );
});
