
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { AnnouncementsProvider } from "./context/AnnouncementsContext";
import { EventsProvider } from "./context/EventsContext";
import { NewsProvider } from "./context/NewsContext";
import { InventoryProvider } from "./context/inventory/InventoryProvider";
import { routes } from "./routes";
import { ProtectedRoute } from "./components/auth/ProtectedRoute";
import NotFound from "./pages/NotFound";
import { useState, useEffect } from "react";
import { ErrorBoundary } from "./components/error/ErrorBoundary";

// Create query client with sensible defaults
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Add this to debug initial rendering
    console.log("App component mounted");
    
    // Simulate loading time to ensure proper initialization
    const timer = setTimeout(() => {
      setIsLoading(false);
      console.log("App finished loading");
    }, 1000);
    
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-background">
        <div className="flex flex-col items-center">
          <div className="h-12 w-12 rounded-full border-4 border-primary border-t-transparent animate-spin"></div>
          <p className="mt-4 text-lg text-muted-foreground">Loading application...</p>
        </div>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <TooltipProvider>
          <Toaster />
          <Sonner />
          <BrowserRouter basename="/">
            <AuthProvider>
              <AnnouncementsProvider>
                <NewsProvider>
                  <EventsProvider>
                    <InventoryProvider>
                      <Routes>
                        {routes.map((route) => (
                          <Route
                            key={route.path}
                            path={route.path}
                            element={
                              route.requiresAuth ? (
                                <ProtectedRoute allowedRoles={route.allowedRoles}>
                                  {route.element}
                                </ProtectedRoute>
                              ) : (
                                route.element
                              )
                            }
                          />
                        ))}
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </InventoryProvider>
                  </EventsProvider>
                </NewsProvider>
              </AnnouncementsProvider>
            </AuthProvider>
          </BrowserRouter>
        </TooltipProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
