
import React, { useState, useEffect } from 'react';
import { Layout } from '@/components/layout/Layout';
import { HeroSection } from '@/components/home/HeroSection';
import { FeaturesSection } from '@/components/home/FeaturesSection';
import { FeaturedImagesSection } from '@/components/home/FeaturedImagesSection';
import { NewsSection } from '@/components/home/NewsSection';
import { EventsSection } from '@/components/home/EventsSection';
import { CTASection } from '@/components/home/CTASection';
import { MessagesSection } from '@/components/home/MessagesSection';
import { schoolImages, featuredImages } from '@/components/home/data/schoolImages';
import { LoadingAnimation } from '@/components/ui-components/LoadingAnimation';

const Index = () => {
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    console.log("Index page mounted");
    
    // Simulate loading time for resources
    const timer = setTimeout(() => {
      setIsLoading(false);
      console.log("Index page finished loading");
    }, 1500);
    
    return () => clearTimeout(timer);
  }, []);
  
  const handleAnimationComplete = () => {
    console.log("Loading animation completed");
    document.body.style.overflow = 'auto';
  };
  
  // Prevent scrolling during loading animation
  useEffect(() => {
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    }
    
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <LoadingAnimation 
          isLoading={isLoading} 
          onAnimationComplete={handleAnimationComplete} 
        />
      )}
      
      <Layout>
        <HeroSection images={schoolImages} />
        <FeaturesSection />
        <MessagesSection />
        <NewsSection />
        <EventsSection />
        <FeaturedImagesSection featuredImages={featuredImages} />
        <CTASection />
      </Layout>
    </>
  );
};

export default Index;
