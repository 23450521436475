
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useAuth } from './AuthContext';
import { toast } from 'sonner';
import { Event, EventInsert, EventUpdate } from '@/types/events';

interface EventsContextType {
  events: Event[];
  featuredEvents: Event[];
  loading: boolean;
  error: string | null;
  createEvent: (eventData: Omit<EventInsert, 'id' | 'created_by' | 'created_at' | 'updated_at'>) => Promise<void>;
  updateEvent: (id: string, eventData: Omit<EventUpdate, 'id' | 'created_by' | 'updated_at'>) => Promise<void>;
  deleteEvent: (id: string) => Promise<void>;
  refreshEvents: () => Promise<void>;
}

const EventsContext = createContext<EventsContextType | undefined>(undefined);

export const EventsProvider = ({ children }: { children: ReactNode }) => {
  const [events, setEvents] = useState<Event[]>([]);
  const [featuredEvents, setFeaturedEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  const fetchEvents = async () => {
    try {
      setLoading(true);
      setError(null);

      // Use explicit type cast to handle the type mismatch
      const { data, error } = await supabase
        .from('events')
        .select('*')
        .order('event_date', { ascending: true });

      if (error) {
        throw error;
      }

      // Type assertion to handle the data
      setEvents(data as Event[] || []);
      
      // Filter featured events
      const featured = (data as Event[])?.filter(event => event.is_featured) || [];
      setFeaturedEvents(featured);
      
    } catch (err: any) {
      console.error('Error fetching events:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const createEvent = async (eventData: Omit<EventInsert, 'id' | 'created_by' | 'created_at' | 'updated_at'>) => {
    try {
      setLoading(true);
      setError(null);

      if (!user) {
        throw new Error('You must be logged in to create an event');
      }

      // Type assertion to handle the insert operation
      const { data, error } = await supabase
        .from('events')
        .insert([
          {
            ...eventData,
            created_by: user.id,
          },
        ] as any)
        .select();

      if (error) {
        throw error;
      }

      toast.success('Event created successfully');
      await fetchEvents();
    } catch (err: any) {
      console.error('Error creating event:', err);
      setError(err.message);
      toast.error(`Failed to create event: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const updateEvent = async (id: string, eventData: Omit<EventUpdate, 'id' | 'created_by' | 'updated_at'>) => {
    try {
      setLoading(true);
      setError(null);

      if (!user) {
        throw new Error('You must be logged in to update an event');
      }

      // Type assertion to handle the update operation
      const { data, error } = await supabase
        .from('events')
        .update({
          ...eventData,
          updated_at: new Date().toISOString(),
        } as any)
        .eq('id', id)
        .select();

      if (error) {
        throw error;
      }

      toast.success('Event updated successfully');
      await fetchEvents();
    } catch (err: any) {
      console.error('Error updating event:', err);
      setError(err.message);
      toast.error(`Failed to update event: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const deleteEvent = async (id: string) => {
    try {
      setLoading(true);
      setError(null);

      if (!user) {
        throw new Error('You must be logged in to delete an event');
      }

      const { error } = await supabase
        .from('events')
        .delete()
        .eq('id', id);

      if (error) {
        throw error;
      }

      toast.success('Event deleted successfully');
      setEvents(events.filter((event) => event.id !== id));
      setFeaturedEvents(featuredEvents.filter((event) => event.id !== id));
    } catch (err: any) {
      console.error('Error deleting event:', err);
      setError(err.message);
      toast.error(`Failed to delete event: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const refreshEvents = async () => {
    await fetchEvents();
  };

  return (
    <EventsContext.Provider
      value={{
        events,
        featuredEvents,
        loading,
        error,
        createEvent,
        updateEvent,
        deleteEvent,
        refreshEvents,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};

export const useEvents = () => {
  const context = useContext(EventsContext);
  if (context === undefined) {
    throw new Error('useEvents must be used within an EventsProvider');
  }
  return context;
};
