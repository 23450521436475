
import React from 'react';
import { Card, CardHeader, CardContent, CardFooter, CardTitle, CardDescription } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { CheckCircle } from 'lucide-react';

export function SuccessMessage() {
  return (
    <Card className="border-green-200 bg-green-50 dark:bg-green-950/20 dark:border-green-900">
      <CardHeader>
        <div className="flex items-center gap-2">
          <CheckCircle className="text-green-600 dark:text-green-400 h-6 w-6" />
          <CardTitle>Application Submitted Successfully</CardTitle>
        </div>
        <CardDescription>
          Thank you for applying to St. Thomas Higher Secondary School
        </CardDescription>
      </CardHeader>
      <CardContent>
        <p className="mb-4">
          Your application has been received and is being processed. You will receive a confirmation
          email shortly with further instructions and information about the admission process.
        </p>
        <p className="mb-4">
          Our admissions team will review your application and contact you within 5-7 working days
          to schedule an interview or entrance test, if applicable.
        </p>
        <p>
          If you have any questions or need further assistance, please contact our admissions office
          at <a href="mailto:admissions@sthss.edu.in" className="text-primary underline">admissions@sthss.edu.in</a> or
          call us at <a href="tel:+1234567890" className="text-primary">+1 (234) 567-890</a>.
        </p>
      </CardContent>
      <CardFooter>
        <Button asChild variant="outline" className="w-full">
          <a href="/">Return to Homepage</a>
        </Button>
      </CardFooter>
    </Card>
  );
}
