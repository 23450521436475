
import React, { RefObject } from 'react';
import { FormControl, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Upload, FileWarning, Check } from 'lucide-react';
import { UseFormReturn } from "react-hook-form";
import { AdmissionFormData, FileUploadField, MAX_FILE_SIZE, ACCEPTED_FILE_TYPES } from "@/types/admissionForm";

interface DocumentsUploadSectionProps {
  form: UseFormReturn<AdmissionFormData>;
  birthCertificateRef: RefObject<HTMLInputElement>;
  aadhaarCardRef: RefObject<HTMLInputElement>;
  markSheetRef: RefObject<HTMLInputElement>;
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>, fieldName: FileUploadField) => void;
}

export function DocumentsUploadSection({ 
  form, 
  birthCertificateRef, 
  aadhaarCardRef, 
  markSheetRef, 
  handleFileChange 
}: DocumentsUploadSectionProps) {
  const renderFileStatus = (fieldName: FileUploadField) => {
    const file = form.watch(fieldName)?.file;
    
    if (!file) return null;
    
    const isValidSize = file.size <= MAX_FILE_SIZE;
    const isValidType = ACCEPTED_FILE_TYPES.includes(file.type);
    const isValid = isValidSize && isValidType;
    
    if (!isValid) {
      return (
        <div className="flex items-center mt-1 text-xs text-destructive">
          <FileWarning className="w-3 h-3 mr-1" />
          {!isValidSize && "File is too large. "}
          {!isValidType && "Invalid file type."}
        </div>
      );
    }
    
    return (
      <div className="flex items-center mt-1 text-xs text-green-600">
        <Check className="w-3 h-3 mr-1" />
        File ready for upload
      </div>
    );
  };
  
  return (
    <div className="bg-muted/30 p-6 rounded-lg">
      <h3 className="text-lg font-medium mb-4">Required Documents</h3>
      <p className="text-sm text-muted-foreground mb-4">
        Please upload clear scanned copies or photos of the following documents (PDF, JPEG, or PNG format only, max 5MB each)
      </p>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FormItem>
          <FormLabel>Birth Certificate <span className="text-destructive">*</span></FormLabel>
          <FormControl>
            <div className="flex flex-col gap-2">
              <input 
                type="file"
                id="birthCertificate"
                ref={birthCertificateRef}
                className="hidden"
                accept={ACCEPTED_FILE_TYPES.join(',')}
                onChange={(e) => handleFileChange(e, 'birthCertificate')}
                aria-invalid={!!form.formState.errors.birthCertificate}
              />
              <Button
                type="button"
                variant="outline"
                onClick={() => birthCertificateRef.current?.click()}
                className={`w-full flex items-center gap-2 ${form.formState.errors.birthCertificate ? 'border-destructive' : ''}`}
              >
                <Upload className="h-4 w-4" />
                {form.watch('birthCertificate')?.name || "Upload Birth Certificate"}
              </Button>
              {renderFileStatus('birthCertificate')}
            </div>
          </FormControl>
          {form.formState.errors.birthCertificate && (
            <FormMessage>
              Birth Certificate is required
            </FormMessage>
          )}
        </FormItem>

        <FormItem>
          <FormLabel>Aadhaar Card <span className="text-destructive">*</span></FormLabel>
          <FormControl>
            <div className="flex flex-col gap-2">
              <input 
                type="file"
                id="aadhaarCard"
                ref={aadhaarCardRef}
                className="hidden"
                accept={ACCEPTED_FILE_TYPES.join(',')}
                onChange={(e) => handleFileChange(e, 'aadhaarCard')}
                aria-invalid={!!form.formState.errors.aadhaarCard}
              />
              <Button
                type="button"
                variant="outline"
                onClick={() => aadhaarCardRef.current?.click()}
                className={`w-full flex items-center gap-2 ${form.formState.errors.aadhaarCard ? 'border-destructive' : ''}`}
              >
                <Upload className="h-4 w-4" />
                {form.watch('aadhaarCard')?.name || "Upload Aadhaar Card"}
              </Button>
              {renderFileStatus('aadhaarCard')}
            </div>
          </FormControl>
          {form.formState.errors.aadhaarCard && (
            <FormMessage>
              Aadhaar Card is required
            </FormMessage>
          )}
        </FormItem>

        <FormItem>
          <FormLabel>Previous Class Report Card/Mark Sheet (optional)</FormLabel>
          <FormControl>
            <div className="flex flex-col gap-2">
              <input 
                type="file"
                id="markSheet"
                ref={markSheetRef}
                className="hidden"
                accept={ACCEPTED_FILE_TYPES.join(',')}
                onChange={(e) => handleFileChange(e, 'markSheet')}
                aria-invalid={!!form.formState.errors.markSheet}
              />
              <Button
                type="button"
                variant="outline"
                onClick={() => markSheetRef.current?.click()}
                className={`w-full flex items-center gap-2 ${form.formState.errors.markSheet ? 'border-destructive' : ''}`}
              >
                <Upload className="h-4 w-4" />
                {form.watch('markSheet')?.name || "Upload Mark Sheet"}
              </Button>
              {renderFileStatus('markSheet')}
            </div>
          </FormControl>
          {form.formState.errors.markSheet && (
            <FormMessage>
              {form.formState.errors.markSheet.file?.message}
            </FormMessage>
          )}
        </FormItem>
      </div>
    </div>
  );
}
