
import React from 'react';
import { FeaturesDropdown } from './FeaturesDropdown';
import { UserMenu } from './UserMenu';
import { Link } from 'react-router-dom';
import { FunButton } from '@/components/ui-components/FunButton';
import { mainNavigation, actionNavigation } from '@/config/navigation';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import { cn } from '@/lib/utils';
import { AboutUsDropdown } from './AboutUsDropdown';

export function DesktopNav() {
  return (
    <nav className="hidden md:flex items-center gap-6">
      <NavigationMenu>
        <NavigationMenuList>
          {mainNavigation.map((item) => (
            <NavigationMenuItem key={item.path}>
              {item.path === '/' ? (
                <Link 
                  to={item.path} 
                  className="text-sm font-medium hover:text-primary transition-colors px-3 py-2"
                >
                  {item.title}
                </Link>
              ) : item.title === 'About Us' ? (
                <AboutUsDropdown />
              ) : (
                <Link 
                  to={item.path} 
                  className="text-sm font-medium hover:text-primary transition-colors px-3 py-2"
                >
                  {item.title}
                </Link>
              )}
            </NavigationMenuItem>
          ))}
        </NavigationMenuList>
      </NavigationMenu>
      
      <FeaturesDropdown />
      
      {actionNavigation.map((item, index) => (
        item.title === 'Inventory Portal' ? (
          <FunButton
            key={item.path}
            component={Link}
            to={item.path}
            className="flex items-center gap-1 bg-green-600 hover:bg-green-700"
            size="sm"
          >
            {item.icon && <item.icon className="h-3 w-3" />}
            {item.title}
          </FunButton>
        ) : (
          <Link 
            key={item.path}
            to={item.path} 
            className="text-sm font-medium hover:text-primary transition-colors"
          >
            {item.title}
          </Link>
        )
      ))}
      
      <UserMenu />
    </nav>
  );
}
