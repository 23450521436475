
import { createContext } from 'react';
import { InventoryItem, AddInventoryItemInput, InventoryChangeRecord } from '@/types/inventory';

interface InventoryContextType {
  inventoryItems: InventoryItem[];
  addItem: (item: AddInventoryItemInput) => void;
  updateItem: (id: string, updatedItem: Partial<InventoryItem>, notes?: string) => void;
  deleteItem: (id: string) => void;
  isInventoryUser: boolean;
  logoutInventory: () => void;
  categories: string[];
  locations: string[];
  getItemById: (id: string) => InventoryItem | undefined;
  getItemHistory: (itemId: string) => InventoryChangeRecord[];
  addChangeRecord: (record: Omit<InventoryChangeRecord, 'id' | 'timestamp'>) => void;
}

export const InventoryContext = createContext<InventoryContextType | undefined>(undefined);
