
import React, { useState, useRef } from 'react';
import { Layout } from '@/components/layout/Layout';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormField } from "@/components/ui/form";
import { 
  admissionFormSchema, 
  AdmissionFormData, 
  FileUploadField,
  MAX_FILE_SIZE,
  ACCEPTED_FILE_TYPES
} from "@/types/admissionForm";
import { toast } from "@/hooks/use-toast";

// Import all our form section components
import { FormHeader } from '@/components/admissions/FormHeader';
import { StudentInfoSection } from '@/components/admissions/StudentInfoSection';
import { ParentInfoSection } from '@/components/admissions/ParentInfoSection';
import { ContactInfoSection } from '@/components/admissions/ContactInfoSection';
import { DocumentsUploadSection } from '@/components/admissions/DocumentsUploadSection';
import { PreviousSchoolSection } from '@/components/admissions/PreviousSchoolSection';
import { AdditionalInfoSection } from '@/components/admissions/AdditionalInfoSection';
import { TermsSection } from '@/components/admissions/TermsSection';
import { SubmitButton } from '@/components/admissions/SubmitButton';
import { SuccessMessage } from '@/components/admissions/SuccessMessage';
import { submitAdmissionForm } from '@/utils/admissionFormSubmit';
import { Sun, Star, Sparkles, CloudSun } from 'lucide-react';
import { motion } from 'framer-motion';

export default function AdmissionForm() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const birthCertificateRef = useRef<HTMLInputElement>(null);
  const aadhaarCardRef = useRef<HTMLInputElement>(null);
  const markSheetRef = useRef<HTMLInputElement>(null);

  const form = useForm<AdmissionFormData>({
    resolver: zodResolver(admissionFormSchema),
    defaultValues: {
      studentName: "",
      dob: "",
      gender: "",
      applyingForClass: "",
      fatherName: "",
      fatherOccupation: "",
      motherName: "",
      motherOccupation: "",
      address: "",
      phoneNumber: "",
      email: "",
      previousSchool: "",
      previousClass: "",
      additionalInfo: "",
    },
  });

  async function onSubmit(values: AdmissionFormData) {
    console.log(values);
    setIsUploading(true);
    
    try {
      const success = await submitAdmissionForm(values);
      if (success) {
        setIsSubmitted(true);
      }
    } finally {
      setIsUploading(false);
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: FileUploadField) => {
    const files = e.target.files;
    if (!files || files.length === 0) return;
    
    const file = files[0];
    if (file.size > MAX_FILE_SIZE) {
      toast({
        title: "File too large",
        description: "File size must be less than 5MB",
        variant: "destructive",
      });
      return;
    }
    
    if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
      toast({
        title: "Invalid file type",
        description: "Only PDF, JPEG, and PNG files are accepted",
        variant: "destructive",
      });
      return;
    }
    
    form.setValue(fieldName, { file, name: file.name }, { 
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };

  return (
    <Layout>
      <div className="container px-4 py-12 md:py-16 mx-auto">
        <div className="max-w-4xl mx-auto relative">
          {/* Decorative elements */}
          <div className="absolute -top-10 -left-10 text-primary opacity-20 wiggle">
            <Sun className="h-24 w-24" />
          </div>
          <div className="absolute top-20 right-5 text-secondary opacity-20 float">
            <Star className="h-16 w-16" />
          </div>
          <div className="absolute bottom-10 left-5 text-accent opacity-20 bounce">
            <Sparkles className="h-16 w-16" />
          </div>
          <div className="absolute -bottom-10 -right-10 text-primary/40 pulse">
            <CloudSun className="h-20 w-20" />
          </div>

          <div className="relative z-10 bg-white/80 backdrop-blur-md dark:bg-slate-900/80 rounded-3xl p-8 border-4 border-primary shadow-xl animate-fade-in">
            <FormHeader />

            {isSubmitted ? (
              <SuccessMessage />
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="text-2xl font-display font-semibold mb-6 text-primary">Application Form</h2>
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                    <StudentInfoSection form={form} />
                    <ParentInfoSection form={form} />
                    <ContactInfoSection form={form} />
                    <DocumentsUploadSection 
                      form={form}
                      birthCertificateRef={birthCertificateRef}
                      aadhaarCardRef={aadhaarCardRef}
                      markSheetRef={markSheetRef}
                      handleFileChange={handleFileChange}
                    />
                    <PreviousSchoolSection form={form} />
                    <AdditionalInfoSection form={form} />
                    <TermsSection form={form} />
                    <SubmitButton isUploading={isUploading} />
                  </form>
                </Form>
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
