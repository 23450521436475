
import React from 'react';
import { Pencil, BookOpen, Stars } from 'lucide-react';

export function FormHeader() {
  return (
    <div className="text-center mb-10 animate-fade-in">
      <div className="flex justify-center mb-4">
        <div className="relative">
          <h1 className="text-4xl md:text-5xl font-display font-bold mb-4 colorful-text">
            Join Our School Family!
          </h1>
          <div className="absolute -top-6 -right-6 text-secondary wiggle">
            <Stars className="h-8 w-8" />
          </div>
          <div className="absolute -bottom-2 -left-6 text-primary bounce">
            <BookOpen className="h-6 w-6" />
          </div>
        </div>
      </div>
      <p className="text-muted-foreground text-lg max-w-2xl mx-auto">
        Fill out this form to start your exciting journey at St. Thomas Higher Secondary School!
        We can't wait to welcome you to our colorful world of learning and fun.
      </p>
      <div className="flex items-center justify-center mt-4 text-primary animate-pulse">
        <Pencil className="mr-2 h-5 w-5" />
        <span className="font-medium">Let's get started!</span>
      </div>
    </div>
  );
}
