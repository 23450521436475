
import React, { memo } from 'react';
import { Badge } from '@/components/ui-components/Badge';
import { EnhancedCard, EnhancedCardContent } from '@/components/ui-components/CardWithStyle';
import { CheckCircle, Smile, Heart, Star } from 'lucide-react';
import { motion } from 'framer-motion';

// Using memo to prevent unnecessary re-renders
const FeatureCard = memo(function FeatureCard({ 
  icon, 
  title, 
  description, 
  iconClass, 
  bgClass, 
  borderClass,
  checkClass,
  features,
  delay
}: {
  icon: React.ReactNode;
  title: string;
  description: string;
  iconClass: string;
  bgClass: string;
  borderClass: string;
  checkClass: string;
  features: string[];
  delay: number;
}) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
    >
      <EnhancedCard hover className={`border-4 ${borderClass} overflow-hidden`}>
        <EnhancedCardContent className="flex flex-col items-center text-center space-y-4 pt-6">
          <div className={`p-3 rounded-full ${bgClass} ${iconClass}`}>
            {icon}
          </div>
          <h3 className={`text-xl font-display ${iconClass.split(' ')[0]}`}>{title}</h3>
          <p className="text-muted-foreground">
            {description}
          </p>
          <ul className="text-sm text-left w-full space-y-2">
            {features.map((feature, index) => (
              <li key={index} className="flex items-center">
                <CheckCircle className={`h-4 w-4 ${checkClass} mr-2 flex-shrink-0`} />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </EnhancedCardContent>
      </EnhancedCard>
    </motion.div>
  );
});

// Memoize the entire FeaturesSection component
export const FeaturesSection = memo(function FeaturesSection() {
  return (
    <section className="py-16 md:py-24">
      <div className="container px-4 md:px-6">
        <motion.div 
          className="flex flex-col items-center text-center space-y-4 mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Badge className="bg-accent text-white font-display px-4 py-1 text-sm">Our School Life</Badge>
          <h2 className="text-3xl md:text-4xl font-display tracking-tighter">
            Colorful School Experience
          </h2>
          <p className="text-muted-foreground md:text-lg max-w-[700px]">
            Discover a world of fun, learning, and friendship at St. Thomas Higher Secondary School!
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* For Students Card */}
          <FeatureCard 
            icon={<Smile className="h-10 w-10" />}
            title="For Students"
            description="Exciting classes, fun activities, and special events that make learning an adventure!"
            iconClass="text-primary wiggle"
            bgClass="bg-primary/10"
            borderClass="border-primary"
            checkClass="text-primary"
            features={[
              "Colorful classrooms & playgrounds",
              "Fun extracurricular activities",
              "Special cultural celebrations"
            ]}
            delay={0.1}
          />

          {/* For Parents Card */}
          <FeatureCard 
            icon={<Heart className="h-10 w-10" />}
            title="For Parents"
            description="Be part of your child's learning journey with regular updates and family events."
            iconClass="text-secondary float"
            bgClass="bg-secondary/10"
            borderClass="border-secondary"
            checkClass="text-secondary"
            features={[
              "Regular parent-teacher meetings",
              "Family participation events",
              "Child progress updates"
            ]}
            delay={0.2}
          />

          {/* For Teachers Card */}
          <FeatureCard 
            icon={<Star className="h-10 w-10" />}
            title="For Teachers"
            description="Create magical learning experiences with our supportive teaching environment."
            iconClass="text-accent bounce"
            bgClass="bg-accent/10"
            borderClass="border-accent"
            checkClass="text-accent"
            features={[
              "Creative teaching resources",
              "Professional development",
              "Collaborative team environment"
            ]}
            delay={0.3}
          />
        </div>
      </div>
    </section>
  );
});
