
import React from 'react';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { Button } from "@/components/ui/button";
import { ChevronDown } from 'lucide-react';
import { LucideIcon } from 'lucide-react';
import { motion } from 'framer-motion';

interface CollapsibleSectionProps {
  title: string;
  icon: LucideIcon;
  isOpen: boolean;
  onToggle: () => void;
  id: string;
  children: React.ReactNode;
}

export function CollapsibleSection({ 
  title, 
  icon: Icon, 
  isOpen, 
  onToggle, 
  id,
  children 
}: CollapsibleSectionProps) {
  return (
    <Collapsible open={isOpen} onOpenChange={onToggle}>
      <motion.div 
        whileHover={{ scale: 1.01 }}
        transition={{ duration: 0.2 }}
      >
        <div className={`rounded-t-lg p-4 flex justify-between items-center cursor-pointer border-4 ${isOpen ? 'border-primary border-b-0' : 'border-primary'} shadow-md`}>
          <h2 className="text-xl font-display flex items-center gap-2 text-primary">
            <Icon className="h-6 w-6 text-primary" />
            {title}
          </h2>
          <CollapsibleTrigger asChild>
            <Button variant="ghost" size="sm" className="rounded-full hover:bg-primary/10">
              <ChevronDown className={`h-5 w-5 transition-transform ${isOpen ? "rotate-180" : ""} text-primary`} />
            </Button>
          </CollapsibleTrigger>
        </div>
      </motion.div>
      <CollapsibleContent className={`bg-white/50 dark:bg-slate-900/50 rounded-b-lg p-6 space-y-4 border-4 border-t-0 border-primary shadow-md`}>
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          {children}
        </motion.div>
      </CollapsibleContent>
    </Collapsible>
  );
}
