
import React from 'react';
import { Link } from 'react-router-dom';

export function Logo() {
  return (
    <Link to="/" className="flex items-center gap-2">
      <img 
        src="/lovable-uploads/f6ce99c3-7765-4d7e-8dd3-df7d44a04500.png" 
        alt="St. Thomas Higher Secondary School" 
        className="h-10 w-10 object-contain"
      />
      <div className="flex flex-col">
        <span className="text-xl font-display font-medium hidden md:inline">St. Thomas Higher Secondary School</span>
        <span className="text-xl font-display font-medium md:hidden">STHSS</span>
        <span className="text-xs text-muted-foreground italic hidden md:inline">"Light to Life"</span>
      </div>
    </Link>
  );
}
