
import React from 'react';
import { 
  EnhancedCard, 
  EnhancedCardHeader, 
  EnhancedCardContent, 
  EnhancedCardFooter, 
  EnhancedCardTitle, 
  EnhancedCardDescription 
} from './CardWithStyle';

// Simple re-export to provide a cleaner API
export const Card = EnhancedCard;
export const CardHeader = EnhancedCardHeader;
export const CardContent = EnhancedCardContent;
export const CardFooter = EnhancedCardFooter;
export const CardTitle = EnhancedCardTitle;
export const CardDescription = EnhancedCardDescription;

// Export a default object for convenience
export default {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  CardTitle,
  CardDescription
};
