
import React, { useState } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ImageIcon, ChevronLeft, ChevronRight } from 'lucide-react';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import { useToast } from '@/hooks/use-toast';

// Mock data - replace with API call to fetch albums from database
const mockAlbums = [
  {
    id: '1',
    title: 'Annual Day Celebration',
    description: 'Highlights from our Annual Day celebration',
    coverImage: '/lovable-uploads/f8f39796-2b5f-417d-9616-5401f91c6d3c.png',
    images: [
      '/lovable-uploads/f8f39796-2b5f-417d-9616-5401f91c6d3c.png',
      '/lovable-uploads/c3e948aa-297f-43b3-a96f-1f456799fd18.png',
    ],
    date: '2023-12-15',
    photoCount: 6
  },
  {
    id: '2',
    title: 'School Structure',
    description: 'Our modern school facilities',
    coverImage: '/lovable-uploads/c3e948aa-297f-43b3-a96f-1f456799fd18.png',
    images: [
      '/lovable-uploads/c3e948aa-297f-43b3-a96f-1f456799fd18.png',
      '/lovable-uploads/f8f39796-2b5f-417d-9616-5401f91c6d3c.png'
    ],
    date: '2023-11-10',
    photoCount: 5
  },
  {
    id: '3',
    title: 'Educational Tour',
    description: 'Students on educational field trip',
    coverImage: '/lovable-uploads/f8f39796-2b5f-417d-9616-5401f91c6d3c.png',
    images: [
      '/lovable-uploads/f8f39796-2b5f-417d-9616-5401f91c6d3c.png',
      '/lovable-uploads/c3e948aa-297f-43b3-a96f-1f456799fd18.png'
    ],
    date: '2023-10-05',
    photoCount: 5
  },
  {
    id: '4',
    title: 'RESULT',
    description: 'Academic achievements of our students',
    coverImage: '/lovable-uploads/c3e948aa-297f-43b3-a96f-1f456799fd18.png',
    images: [
      '/lovable-uploads/c3e948aa-297f-43b3-a96f-1f456799fd18.png', 
      '/lovable-uploads/f8f39796-2b5f-417d-9616-5401f91c6d3c.png'
    ],
    date: '2023-09-20',
    photoCount: 6
  }
];

type AlbumGridProps = {
  albums: typeof mockAlbums;
  onAlbumClick: (albumId: string) => void;
};

const AlbumGrid = ({ albums, onAlbumClick }: AlbumGridProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {albums.map((album) => (
        <Card key={album.id} className="overflow-hidden hover:shadow-lg transition-shadow">
          <div 
            className="h-52 cursor-pointer" 
            onClick={() => onAlbumClick(album.id)}
          >
            {album.coverImage ? (
              <img 
                src={album.coverImage} 
                alt={album.title} 
                className="w-full h-full object-cover"
              />
            ) : (
              <div className="w-full h-full flex items-center justify-center bg-muted">
                <ImageIcon className="w-12 h-12 text-muted-foreground" />
              </div>
            )}
          </div>
          <CardContent className="p-4">
            <h3 className="text-lg font-medium mb-1">{album.title}</h3>
            <p className="text-sm text-muted-foreground mb-2">{album.description}</p>
            <div className="flex justify-between items-center mt-2">
              <span className="text-xs text-muted-foreground">{album.date}</span>
              <span className="text-xs font-medium">{album.photoCount} photos</span>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

type AlbumViewerProps = {
  album: typeof mockAlbums[0] | null;
  onClose: () => void;
};

const AlbumViewer = ({ album, onClose }: AlbumViewerProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
  if (!album) return null;
  
  const nextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === album.images.length - 1 ? 0 : prev + 1
    );
  };
  
  const prevImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? album.images.length - 1 : prev - 1
    );
  };
  
  return (
    <div className="relative">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">{album.title}</h2>
        <Button variant="ghost" size="sm" onClick={onClose}>Close</Button>
      </div>
      
      <div className="relative aspect-video bg-black rounded-lg overflow-hidden">
        <img 
          src={album.images[currentImageIndex]} 
          alt={`${album.title} - Image ${currentImageIndex + 1}`} 
          className="w-full h-full object-contain"
        />
        
        <Button 
          variant="secondary" 
          size="icon" 
          className="absolute left-2 top-1/2 -translate-y-1/2 rounded-full"
          onClick={prevImage}
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        
        <Button 
          variant="secondary" 
          size="icon" 
          className="absolute right-2 top-1/2 -translate-y-1/2 rounded-full"
          onClick={nextImage}
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
      
      <div className="mt-2 text-center text-sm text-muted-foreground">
        Image {currentImageIndex + 1} of {album.images.length}
      </div>
      
      <div className="grid grid-cols-6 gap-2 mt-4">
        {album.images.map((image, index) => (
          <div 
            key={index}
            className={`aspect-square cursor-pointer rounded-md overflow-hidden ${
              index === currentImageIndex ? 'ring-2 ring-primary' : ''
            }`}
            onClick={() => setCurrentImageIndex(index)}
          >
            <img 
              src={image} 
              alt={`Thumbnail ${index + 1}`} 
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export function ImageGallery() {
  const [selectedAlbum, setSelectedAlbum] = useState<typeof mockAlbums[0] | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { toast } = useToast();
  
  const handleAlbumClick = (albumId: string) => {
    const album = mockAlbums.find(a => a.id === albumId);
    if (album) {
      setSelectedAlbum(album);
      setDialogOpen(true);
    } else {
      toast({
        title: "Album not found",
        description: "The selected album could not be found",
        variant: "destructive"
      });
    }
  };
  
  return (
    <div>
      <AlbumGrid albums={mockAlbums} onAlbumClick={handleAlbumClick} />
      
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="max-w-4xl">
          <AlbumViewer 
            album={selectedAlbum} 
            onClose={() => setDialogOpen(false)} 
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
