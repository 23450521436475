
import React from 'react';
import { Link } from 'react-router-dom';
import { featuresNavigation } from '@/config/navigation';

interface MobileFeaturesProps {
  onItemClick: () => void;
}

export function MobileFeatures({ onItemClick }: MobileFeaturesProps) {
  return (
    <div className="border-l-2 border-muted pl-3 py-1">
      <p className="text-sm font-medium mb-2">Features</p>
      <div className="flex flex-col gap-2 ml-2">
        {featuresNavigation.map((item) => (
          <Link 
            key={item.path}
            to={item.path} 
            className="flex items-center gap-2 py-1 text-sm font-medium hover:text-primary transition-colors"
            onClick={onItemClick}
          >
            <item.icon className="h-4 w-4" />
            {item.title}
          </Link>
        ))}
      </div>
    </div>
  );
}
