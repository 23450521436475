
import React, { useState } from 'react';
import { Layout } from '@/components/layout/Layout';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ImageGallery } from '@/components/gallery/ImageGallery';
import { VideoGallery } from '@/components/gallery/VideoGallery';
import { Separator } from '@/components/ui/separator';

const Gallery = () => {
  const [activeTab, setActiveTab] = useState<'images' | 'videos'>('images');

  return (
    <Layout>
      <div className="container py-8 px-4 animate-fade-in">
        <div className="max-w-6xl mx-auto">
          <div className="mb-8">
            <h1 className="text-3xl font-bold tracking-tight">School Gallery</h1>
            <p className="text-muted-foreground mt-2">
              Explore photos and videos from our school events and activities
            </p>
            <Separator className="my-4" />
          </div>

          <Tabs 
            defaultValue="images" 
            className="w-full"
            onValueChange={(value) => setActiveTab(value as 'images' | 'videos')}
          >
            <div className="flex justify-center mb-6">
              <TabsList className="grid w-full max-w-md grid-cols-2">
                <TabsTrigger value="images">Images</TabsTrigger>
                <TabsTrigger value="videos">Videos</TabsTrigger>
              </TabsList>
            </div>

            <TabsContent value="images" className="mt-4">
              <ImageGallery />
            </TabsContent>

            <TabsContent value="videos" className="mt-4">
              <VideoGallery />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </Layout>
  );
};

export default Gallery;
