
import { ReactNode, lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { ProtectedRoute } from '@/components/auth/ProtectedRoute';

// Loading component for Suspense
const Loading = () => <div className="flex items-center justify-center p-8">Loading...</div>;
// Pages
// Pages
import Index from '@/pages/Index';
import Login from '@/pages/Login';
const Dashboard = lazy(() => import('@/pages/Dashboard'));
import Profile from '@/pages/Profile';
import AboutUs from '@/pages/AboutUs';
// About Us section pages
import SchoolDetails from '@/pages/about/SchoolDetails';
import OurTeam from '@/pages/about/OurTeam';

import OnlineAdmissions from '@/pages/OnlineAdmissions';
import AdmissionForm from '@/pages/AdmissionForm';
import Notice from '@/pages/Notice';
import Syllabus from '@/pages/Syllabus';
import PrincipalsMessage from '@/pages/PrincipalsMessage';
import ChairmansMessage from '@/pages/ChairmansMessage';
import NotFound from '@/pages/NotFound';
import Gallery from '@/pages/Gallery';

// Gallery Admin
const GalleryManagement = lazy(() => import('@/pages/admin/GalleryManagement'));

// Fee Portal Routes
const FeePortal = lazy(() => import('@/pages/FeePortal'));
const StudentFeeLogin = lazy(() => import('@/pages/feeportal/StudentFeeLogin'));
const CashierLogin = lazy(() => import('@/pages/feeportal/CashierLogin'));
const StudentFeeDashboard = lazy(() => import('@/pages/feeportal/StudentFeeDashboard'));
const CashierDashboard = lazy(() => import('@/pages/feeportal/CashierDashboard'));
// Admin Routes
const RoleManagement = lazy(() => import('@/pages/admin/RoleManagement'));
const AdmissionApplications = lazy(() => import('@/pages/admin/AdmissionApplications'));
const AdmissionApplicationDetail = lazy(() => import('@/pages/admin/AdmissionApplicationDetail'));
// Inventory Routes
const InventoryLogin = lazy(() => import('@/pages/inventory/InventoryLogin'));
const InventoryDashboard = lazy(() => import('@/pages/inventory/InventoryDashboard'));
const InventoryList = lazy(() => import('@/pages/inventory/InventoryList'));
const AddInventoryItem = lazy(() => import('@/pages/inventory/AddInventoryItem'));
const EditInventoryItem = lazy(() => import('@/pages/inventory/EditInventoryItem'));
const InventoryItemDetail = lazy(() => import('@/pages/inventory/InventoryItemDetail'));
// Route types
export interface RouteConfig {
  path: string;
  element: ReactNode;
  children?: RouteConfig[];
  requiresAuth?: boolean;
  allowedRoles?: ('teacher' | 'student' | 'admin')[];
}

// Routes configuration
export const routes: RouteConfig[] = [
  {
    path: '/',
    element: <Index />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/dashboard',
    element: <Suspense fallback={<Loading />}><Dashboard /></Suspense>,
    requiresAuth: true,
  },
  {
    path: '/profile',
    element: <Profile />,
    requiresAuth: true,
  },
  {
    path: '/about-us',
    element: <AboutUs />,
  },
  {
    path: '/about-us/school-details',
    element: <SchoolDetails />,
  },
  {
    path: '/about-us/our-team',
    element: <OurTeam />,
  },
  {
    path: '/gallery',
    element: <Gallery />,
  },
  {
    path: '/notice',
    element: <Notice />,
  },
  {
    path: '/online-admissions',
    element: <OnlineAdmissions />,
  },
  {
    path: '/admission-form',
    element: <AdmissionForm />,
  },
  {
    path: '/syllabus',
    element: <Syllabus />,
  },
  {
    path: '/principals-message',
    element: <PrincipalsMessage />,
  },
  {
    path: '/chairmans-message',
    element: <ChairmansMessage />,
  },
  {
    path: '/fee-portal',
    element: <Suspense fallback={<Loading />}><FeePortal /></Suspense>,
  },
  {
    path: '/fee-portal/student-login',
    element: <Suspense fallback={<Loading />}><StudentFeeLogin /></Suspense>,
  },
  {
    path: '/fee-portal/cashier-login',
    element: <Suspense fallback={<Loading />}><CashierLogin /></Suspense>,
  },
  {
    path: '/fee-portal/student-dashboard',
    element: <Suspense fallback={<Loading />}><StudentFeeDashboard /></Suspense>,
    requiresAuth: true,
  },
  {
    path: '/fee-portal/cashier-dashboard',
    element: <Suspense fallback={<Loading />}><CashierDashboard /></Suspense>,
    requiresAuth: true,
  },
  {
    path: '/admin/role-management',
    element: <Suspense fallback={<Loading />}><RoleManagement /></Suspense>,
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  {
    path: '/admin/admissions',
    element: <Suspense fallback={<Loading />}><AdmissionApplications /></Suspense>,
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  {
    path: '/admin/admissions/:id',
    element: <Suspense fallback={<Loading />}><AdmissionApplicationDetail /></Suspense>,
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  {
    path: '/admin/gallery',
    element: <Suspense fallback={<Loading />}><GalleryManagement /></Suspense>,
    requiresAuth: true,
    allowedRoles: ['admin'],
  },
  {
    path: '/inventory/login',
    element: <Suspense fallback={<Loading />}><InventoryLogin /></Suspense>,
  },
  {
    path: '/inventory/dashboard',
    element: <Suspense fallback={<Loading />}><InventoryDashboard /></Suspense>,
    requiresAuth: true,
  },
  {
    path: '/inventory/list',
    element: <Suspense fallback={<Loading />}><InventoryList /></Suspense>,
    requiresAuth: true,
  },
  {
    path: '/inventory/add-item',
    element: <Suspense fallback={<Loading />}><AddInventoryItem /></Suspense>,
    requiresAuth: true,
  },
  {
    path: '/inventory/edit/:id',
    element: <Suspense fallback={<Loading />}><EditInventoryItem /></Suspense>,
    requiresAuth: true,
  },
  {
    path: '/inventory/item/:id',
    element: <Suspense fallback={<Loading />}><InventoryItemDetail /></Suspense>,
    requiresAuth: true,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
