
import React from 'react';
import { FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UseFormReturn } from "react-hook-form";
import { AdmissionFormData } from "@/types/admissionForm";

interface PreviousSchoolSectionProps {
  form: UseFormReturn<AdmissionFormData>;
}

export function PreviousSchoolSection({ form }: PreviousSchoolSectionProps) {
  return (
    <div className="bg-muted/30 p-6 rounded-lg">
      <h3 className="text-lg font-medium mb-4">Previous School Information (if applicable)</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <FormField
          control={form.control}
          name="previousSchool"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Previous School Name</FormLabel>
              <FormControl>
                <Input placeholder="Enter previous school name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="previousClass"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Last Class Attended</FormLabel>
              <FormControl>
                <Input placeholder="Enter last class attended" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
}
