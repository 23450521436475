
import React, { CSSProperties, ReactNode } from 'react';
import { 
  Card as ShadcnCard, 
  CardHeader as ShadcnCardHeader,
  CardContent as ShadcnCardContent,
  CardFooter as ShadcnCardFooter,
  CardTitle as ShadcnCardTitle,
  CardDescription as ShadcnCardDescription
} from '@/components/ui/card';
import { cn } from '@/lib/utils';

// Enhanced Card component that extends shadcn/ui card with additional features
interface EnhancedCardProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  hover?: boolean;
  glass?: boolean;
  onClick?: () => void;
}

export function EnhancedCard({ 
  children, 
  className, 
  style, 
  hover,
  glass,
  onClick
}: EnhancedCardProps) {
  return (
    <ShadcnCard 
      className={cn(
        hover && 'transition-all duration-300 hover:shadow-lg hover:-translate-y-1',
        glass && 'glass',
        onClick && 'cursor-pointer',
        className
      )} 
      style={style}
      onClick={onClick}
    >
      {children}
    </ShadcnCard>
  );
}

interface EnhancedCardHeaderProps {
  children: ReactNode;
  className?: string;
}

export function EnhancedCardHeader({ children, className }: EnhancedCardHeaderProps) {
  return <ShadcnCardHeader className={className}>{children}</ShadcnCardHeader>;
}

interface EnhancedCardContentProps {
  children: ReactNode;
  className?: string;
}

export function EnhancedCardContent({ children, className }: EnhancedCardContentProps) {
  return <ShadcnCardContent className={className}>{children}</ShadcnCardContent>;
}

interface EnhancedCardFooterProps {
  children: ReactNode;
  className?: string;
}

export function EnhancedCardFooter({ children, className }: EnhancedCardFooterProps) {
  return <ShadcnCardFooter className={className}>{children}</ShadcnCardFooter>;
}

interface EnhancedCardTitleProps {
  children: ReactNode;
  className?: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export function EnhancedCardTitle({ children, className, as = 'h3' }: EnhancedCardTitleProps) {
  const Component = as;
  return (
    <Component className={cn('font-semibold leading-none tracking-tight', className)}>
      {children}
    </Component>
  );
}

interface EnhancedCardDescriptionProps {
  children: ReactNode;
  className?: string;
}

export function EnhancedCardDescription({ children, className }: EnhancedCardDescriptionProps) {
  return <ShadcnCardDescription className={className}>{children}</ShadcnCardDescription>;
}

// Re-export original components for backward compatibility
export {
  ShadcnCard as Card,
  ShadcnCardHeader as CardHeader,
  ShadcnCardContent as CardContent,
  ShadcnCardFooter as CardFooter,
  ShadcnCardTitle as CardTitle,
  ShadcnCardDescription as CardDescription,
};

// Re-export enhanced components with old names for backward compatibility
export {
  EnhancedCard as CardWithStyle,
  EnhancedCardHeader as CardHeaderWithStyle,
  EnhancedCardContent as CardContentWithStyle,
  EnhancedCardFooter as CardFooterWithStyle,
  EnhancedCardTitle as CardTitleWithStyle,
  EnhancedCardDescription as CardDescriptionWithStyle,
};
