
import { InventoryItem } from '@/types/inventory';

// Helper for calculating item status based on quantity and minQuantity
export const calculateStatus = (quantity: number, minQuantity: number): InventoryItem['status'] => {
  if (quantity <= 0) return 'critical';
  if (quantity < minQuantity) return 'low';
  if (quantity > minQuantity * 3) return 'overstocked';
  return 'available';
};

// Sample inventory data
export const sampleInventoryItems: InventoryItem[] = [
  {
    id: '1',
    name: 'Notebooks',
    category: 'Stationery',
    quantity: 250,
    unit: 'pieces',
    location: 'Main Storage',
    lastUpdated: '2025-03-25',
    minQuantity: 50,
    status: 'available',
  },
  {
    id: '2',
    name: 'Chalk Boxes',
    category: 'Teaching Supplies',
    quantity: 30,
    unit: 'boxes',
    location: 'Teacher Room Storage',
    lastUpdated: '2025-03-20',
    minQuantity: 20,
    status: 'low',
  },
  {
    id: '3',
    name: 'Science Lab Equipment',
    category: 'Lab Supplies',
    quantity: 15,
    unit: 'sets',
    location: 'Science Lab',
    lastUpdated: '2025-03-15',
    minQuantity: 10,
    status: 'available',
  },
  {
    id: '4',
    name: 'Whiteboard Markers',
    category: 'Teaching Supplies',
    quantity: 10,
    unit: 'boxes',
    location: 'Teacher Room Storage',
    lastUpdated: '2025-03-22',
    minQuantity: 15,
    status: 'critical',
  },
  {
    id: '5',
    name: 'Sports Equipment',
    category: 'Physical Education',
    quantity: 45,
    unit: 'pieces',
    location: 'Sports Room',
    lastUpdated: '2025-03-18',
    minQuantity: 20,
    status: 'available',
  }
];
