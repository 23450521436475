
import { FileText, BookOpen, CreditCard, GraduationCap, Package, Home, User, Info, Users, Image } from 'lucide-react';

export interface NavigationItem {
  title: string;
  path: string;
  icon: any;
  children?: NavigationItem[];
}

export const mainNavigation: NavigationItem[] = [
  {
    title: 'Home',
    path: '/',
    icon: Home,
  },
  {
    title: 'About Us',
    path: '/about-us',
    icon: User,
    children: [
      {
        title: 'School Details',
        path: '/about-us/school-details',
        icon: Info,
      },
      {
        title: 'Our Team',
        path: '/about-us/our-team',
        icon: Users,
      }
    ]
  },
  {
    title: 'Gallery',
    path: '/gallery',
    icon: Image,
  },
];

export const featuresNavigation: NavigationItem[] = [
  {
    title: 'Notice',
    path: '/notice',
    icon: FileText,
  },
  {
    title: 'Syllabus',
    path: '/syllabus',
    icon: BookOpen,
  },
  {
    title: 'Fee Portal',
    path: '/fee-portal',
    icon: CreditCard,
  },
];

export const actionNavigation: NavigationItem[] = [
  {
    title: 'Online Admissions',
    path: '/online-admissions',
    icon: GraduationCap,
  },
  {
    title: 'Inventory Portal',
    path: '/inventory/login',
    icon: Package,
  },
];
