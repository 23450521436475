
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FunButton } from '@/components/ui-components/FunButton';
import { MobileFeatures } from './MobileFeatures';
import { MobileUserMenu } from './MobileUserMenu';
import { mainNavigation, actionNavigation } from '@/config/navigation';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface MobileNavProps {
  isMenuOpen: boolean;
  onCloseMenu: () => void;
}

export function MobileNav({ isMenuOpen, onCloseMenu }: MobileNavProps) {
  const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({});
  
  if (!isMenuOpen) return null;
  
  const toggleExpand = (title: string) => {
    setExpandedItems(prev => ({
      ...prev,
      [title]: !prev[title]
    }));
  };
  
  return (
    <div className="md:hidden container px-4 pb-4 animate-fade-in">
      <nav className="flex flex-col gap-4">
        {mainNavigation.map((item) => (
          <div key={item.path}>
            {item.children ? (
              <div className="flex flex-col">
                <button 
                  className="flex items-center justify-between py-2 text-sm font-medium hover:text-primary transition-colors"
                  onClick={() => toggleExpand(item.title)}
                >
                  <div className="flex items-center gap-2">
                    <item.icon className="h-4 w-4" />
                    {item.title}
                  </div>
                  {expandedItems[item.title] ? (
                    <ChevronUp className="h-4 w-4" />
                  ) : (
                    <ChevronDown className="h-4 w-4" />
                  )}
                </button>
                
                {expandedItems[item.title] && (
                  <div className="pl-6 flex flex-col gap-2 mt-1">
                    {item.children.map((child) => (
                      <Link
                        key={child.path}
                        to={child.path}
                        className="flex items-center gap-2 py-2 text-sm font-medium hover:text-primary transition-colors"
                        onClick={onCloseMenu}
                      >
                        <child.icon className="h-4 w-4" />
                        {child.title}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <Link 
                to={item.path} 
                className="flex items-center gap-2 py-2 text-sm font-medium hover:text-primary transition-colors"
                onClick={onCloseMenu}
              >
                <item.icon className="h-4 w-4" />
                {item.title}
              </Link>
            )}
          </div>
        ))}
        
        <MobileFeatures onItemClick={onCloseMenu} />
        
        {actionNavigation.map((item) => (
          item.title === 'Inventory Portal' ? (
            <div key={item.path} className="py-2">
              <FunButton
                component={Link}
                to={item.path}
                className="flex items-center gap-1 bg-green-600 hover:bg-green-700"
                size="sm"
                onClick={onCloseMenu}
              >
                <item.icon className="h-3 w-3" />
                {item.title}
              </FunButton>
            </div>
          ) : (
            <Link 
              key={item.path}
              to={item.path} 
              className="flex items-center gap-2 py-2 text-sm font-medium hover:text-primary transition-colors"
              onClick={onCloseMenu}
            >
              <item.icon className="h-4 w-4" />
              {item.title}
            </Link>
          )
        ))}
        
        <MobileUserMenu onItemClick={onCloseMenu} />
      </nav>
    </div>
  );
}
