
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useAuth } from './AuthContext';
import { toast } from 'sonner';
import { News, NewsInsert, NewsUpdate } from '@/types/news';

interface NewsContextType {
  newsItems: News[];
  featuredNews: News[];
  loading: boolean;
  error: string | null;
  createNewsItem: (newsData: Omit<NewsInsert, 'id' | 'created_by' | 'created_at' | 'updated_at'>) => Promise<void>;
  updateNewsItem: (id: string, newsData: Omit<NewsUpdate, 'id' | 'created_by' | 'updated_at'>) => Promise<void>;
  deleteNewsItem: (id: string) => Promise<void>;
  refreshNews: () => Promise<void>;
}

const NewsContext = createContext<NewsContextType | undefined>(undefined);

export const NewsProvider = ({ children }: { children: ReactNode }) => {
  const [newsItems, setNewsItems] = useState<News[]>([]);
  const [featuredNews, setFeaturedNews] = useState<News[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  const fetchNews = async () => {
    try {
      setLoading(true);
      setError(null);

      // Use explicit type cast to handle the type mismatch
      const { data, error } = await supabase
        .from('news')
        .select('*')
        .order('published_at', { ascending: false });

      if (error) {
        throw error;
      }

      // Type assertion to handle the data
      setNewsItems(data as News[] || []);
      
      // Filter featured news
      const featured = (data as News[])?.filter(item => item.is_featured) || [];
      setFeaturedNews(featured);
      
    } catch (err: any) {
      console.error('Error fetching news:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  const createNewsItem = async (newsData: Omit<NewsInsert, 'id' | 'created_by' | 'created_at' | 'updated_at'>) => {
    try {
      setLoading(true);
      setError(null);

      if (!user) {
        throw new Error('You must be logged in to create a news item');
      }

      // Type assertion to handle the insert operation
      const { data, error } = await supabase
        .from('news')
        .insert([
          {
            ...newsData,
            created_by: user.id,
          },
        ] as any)
        .select();

      if (error) {
        throw error;
      }

      toast.success('News item created successfully');
      await fetchNews();
    } catch (err: any) {
      console.error('Error creating news item:', err);
      setError(err.message);
      toast.error(`Failed to create news item: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const updateNewsItem = async (id: string, newsData: Omit<NewsUpdate, 'id' | 'created_by' | 'updated_at'>) => {
    try {
      setLoading(true);
      setError(null);

      if (!user) {
        throw new Error('You must be logged in to update a news item');
      }

      // Type assertion to handle the update operation
      const { data, error } = await supabase
        .from('news')
        .update({
          ...newsData,
          updated_at: new Date().toISOString(),
        } as any)
        .eq('id', id)
        .select();

      if (error) {
        throw error;
      }

      toast.success('News item updated successfully');
      await fetchNews();
    } catch (err: any) {
      console.error('Error updating news item:', err);
      setError(err.message);
      toast.error(`Failed to update news item: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const deleteNewsItem = async (id: string) => {
    try {
      setLoading(true);
      setError(null);

      if (!user) {
        throw new Error('You must be logged in to delete a news item');
      }

      const { error } = await supabase
        .from('news')
        .delete()
        .eq('id', id);

      if (error) {
        throw error;
      }

      toast.success('News item deleted successfully');
      setNewsItems(newsItems.filter((item) => item.id !== id));
      setFeaturedNews(featuredNews.filter((item) => item.id !== id));
    } catch (err: any) {
      console.error('Error deleting news item:', err);
      setError(err.message);
      toast.error(`Failed to delete news item: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const refreshNews = async () => {
    await fetchNews();
  };

  return (
    <NewsContext.Provider
      value={{
        newsItems,
        featuredNews,
        loading,
        error,
        createNewsItem,
        updateNewsItem,
        deleteNewsItem,
        refreshNews,
      }}
    >
      {children}
    </NewsContext.Provider>
  );
};

export const useNews = () => {
  const context = useContext(NewsContext);
  if (context === undefined) {
    throw new Error('useNews must be used within a NewsProvider');
  }
  return context;
};
