
import React from 'react';

export function RequiredDocuments() {
  return (
    <>
      <p>
        The following documents are required to be submitted along with the application form:
      </p>
      <ul className="list-disc list-inside space-y-2">
        <li className="font-medium">Birth Certificate (original and photocopy) <span className="text-destructive">*</span></li>
        <li>Transfer Certificate from the previous school (for Classes 1 and above)</li>
        <li>Report Card/Mark Sheet of the previous class</li>
        <li>Two recent passport-size photographs</li>
        <li className="font-medium">Aadhar Card (photocopy) <span className="text-destructive">*</span></li>
        <li>Residential proof (Electricity bill/Telephone bill/Ration card)</li>
      </ul>
      <p className="text-sm text-muted-foreground italic mt-4">
        <span className="text-destructive">*</span> Mandatory documents required for submission of the application form.
      </p>
      <p className="text-sm text-muted-foreground italic mt-2">
        Note: Original documents need to be presented for verification at the time of admission.
      </p>
    </>
  );
}
