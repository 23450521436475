
import React from 'react';
import { FormField, FormItem, FormLabel, FormControl, FormDescription, FormMessage } from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { UseFormReturn } from "react-hook-form";
import { AdmissionFormData } from "@/types/admissionForm";

interface AdditionalInfoSectionProps {
  form: UseFormReturn<AdmissionFormData>;
}

export function AdditionalInfoSection({ form }: AdditionalInfoSectionProps) {
  return (
    <div className="bg-muted/30 p-6 rounded-lg">
      <FormField
        control={form.control}
        name="additionalInfo"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Additional Information</FormLabel>
            <FormControl>
              <Textarea
                placeholder="Any additional information you would like to share"
                className="min-h-[100px]"
                {...field}
              />
            </FormControl>
            <FormDescription>
              Please mention any special needs, achievements, or relevant information.
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
}
