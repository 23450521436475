
import React from 'react';
import { User } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/context/AuthContext';
import { Link } from 'react-router-dom';

interface MobileUserMenuProps {
  onItemClick: () => void;
}

export function MobileUserMenu({ onItemClick }: MobileUserMenuProps) {
  const { user, logout } = useAuth();

  if (user) {
    return (
      <>
        <Link 
          to="/dashboard" 
          className="flex items-center gap-2 py-2 text-sm font-medium hover:text-primary transition-colors"
          onClick={onItemClick}
        >
          Dashboard
        </Link>
        <Link 
          to="/profile" 
          className="flex items-center gap-2 py-2 text-sm font-medium hover:text-primary transition-colors"
          onClick={onItemClick}
        >
          Profile
        </Link>
        <Button 
          variant="ghost" 
          className="justify-start px-0 hover:bg-transparent"
          onClick={() => {
            logout();
            onItemClick();
          }}
        >
          <User className="mr-2 h-4 w-4" />
          Logout
        </Button>
      </>
    );
  }

  return (
    <Button 
      onClick={onItemClick}
      asChild
      className="w-full"
    >
      <Link to="/login">
        Login
      </Link>
    </Button>
  );
}
