
import React, { useState } from 'react';
import { Layout } from '@/components/layout/Layout';
import { 
  Calendar as CalendarIcon, 
  Download, 
  FileText, 
  Bell, 
  ChevronDown,
  Filter
} from 'lucide-react';
import { 
  CardWithStyle, 
  CardHeader, 
  CardTitle, 
  CardDescription, 
  CardContent 
} from '@/components/ui-components/CardWithStyle';
import { Badge } from '@/components/ui-components/Badge';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Separator } from '@/components/ui/separator';
import { 
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { format } from "date-fns";
import { ButtonWithIcon } from '@/components/ui-components/ButtonWithIcon';

export default function Notice() {
  const [date, setDate] = useState<Date | undefined>(new Date());
  const [category, setCategory] = useState<string>("all");
  
  // Example notices data
  const notices = [
    {
      id: 1,
      title: "Annual Sports Day Schedule",
      category: "event",
      date: "2023-06-15",
      content: "The Annual Sports Day will be held on June 20th, 2023. All students are required to participate in at least one event. Parents are cordially invited to attend the ceremony.",
      important: true
    },
    {
      id: 2,
      title: "Parent-Teacher Meeting",
      category: "meeting",
      date: "2023-06-10",
      content: "A Parent-Teacher Meeting is scheduled for all classes on June 18th, 2023 from 9:00 AM to 1:00 PM. Parents are requested to attend without fail to discuss their ward's academic progress.",
      important: true
    },
    {
      id: 3,
      title: "Holiday Notice - Independence Day",
      category: "holiday",
      date: "2023-08-13",
      content: "The school will remain closed on August 15th, 2023 on account of Independence Day. A special assembly will be held on August 14th to commemorate the occasion.",
      important: false
    },
    {
      id: 4,
      title: "Examination Schedule - Term 1",
      category: "exam",
      date: "2023-07-25",
      content: "The Term 1 examinations will commence from August 5th, 2023. The detailed schedule has been shared with students. Please ensure your ward prepares accordingly.",
      important: true
    },
    {
      id: 5,
      title: "Fees Payment Reminder",
      category: "administrative",
      date: "2023-06-05",
      content: "This is a reminder that the last date for payment of Term 2 fees is June 25th, 2023. Late payment will attract a fine as per school policy.",
      important: false
    }
  ];
  
  // Example calendar events
  const calendarEvents = [
    { id: 1, date: new Date(2023, 5, 20), title: "Annual Sports Day", category: "event" },
    { id: 2, date: new Date(2023, 5, 18), title: "Parent-Teacher Meeting", category: "meeting" },
    { id: 3, date: new Date(2023, 7, 15), title: "Independence Day", category: "holiday" },
    { id: 4, date: new Date(2023, 7, 5), title: "Term 1 Examinations Begin", category: "exam" },
    { id: 5, date: new Date(2023, 7, 20), title: "Term 1 Examinations End", category: "exam" },
  ];
  
  // Filter notices based on selected category
  const filteredNotices = category === "all" 
    ? notices 
    : notices.filter(notice => notice.category === category);
  
  // Check if a date has an event
  const hasEvent = (day: Date) => {
    return calendarEvents.some(event => 
      event.date.getDate() === day.getDate() && 
      event.date.getMonth() === day.getMonth() && 
      event.date.getFullYear() === day.getFullYear()
    );
  };
  
  // Get events for the selected date
  const eventsForSelectedDate = date 
    ? calendarEvents.filter(event => 
        event.date.getDate() === date.getDate() && 
        event.date.getMonth() === date.getMonth() && 
        event.date.getFullYear() === date.getFullYear()
      ) 
    : [];
  
  return (
    <Layout>
      <div className="container px-4 py-12 md:py-16 mx-auto">
        <div className="max-w-5xl mx-auto">
          <div className="text-center mb-10">
            <h1 className="text-3xl md:text-4xl font-bold mb-4">School Notices & Calendar</h1>
            <p className="text-lg text-muted-foreground">
              Stay updated with the latest announcements, events, and academic calendar
            </p>
          </div>
          
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Notices Section */}
            <div className="lg:col-span-2 space-y-6">
              <CardWithStyle>
                <CardHeader className="flex flex-row items-center justify-between">
                  <div>
                    <CardTitle>Notices & Circulars</CardTitle>
                    <CardDescription>Important announcements and information</CardDescription>
                  </div>
                  <div className="flex items-center gap-2">
                    <Select value={category} onValueChange={setCategory}>
                      <SelectTrigger className="w-[130px]">
                        <SelectValue placeholder="Filter by" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="all">All Notices</SelectItem>
                        <SelectItem value="event">Events</SelectItem>
                        <SelectItem value="meeting">Meetings</SelectItem>
                        <SelectItem value="holiday">Holidays</SelectItem>
                        <SelectItem value="exam">Examinations</SelectItem>
                        <SelectItem value="administrative">Administrative</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                </CardHeader>
                <CardContent>
                  <Accordion type="single" collapsible className="w-full">
                    {filteredNotices.length > 0 ? (
                      filteredNotices.map(notice => (
                        <AccordionItem key={notice.id} value={`notice-${notice.id}`}>
                          <AccordionTrigger className="hover:no-underline">
                            <div className="flex items-center gap-3 text-left">
                              <FileText className="h-5 w-5 text-primary" />
                              <div>
                                <div className="flex items-center gap-2">
                                  <span className="font-medium">{notice.title}</span>
                                  {notice.important && (
                                    <Badge variant="danger" className="ml-2">Important</Badge>
                                  )}
                                </div>
                                <p className="text-xs text-muted-foreground">
                                  {notice.date}
                                </p>
                              </div>
                            </div>
                          </AccordionTrigger>
                          <AccordionContent className="text-muted-foreground">
                            <div className="space-y-4 pt-2">
                              <p>{notice.content}</p>
                              <div className="flex items-center justify-end">
                                <Badge variant="outline" className="capitalize">
                                  {notice.category}
                                </Badge>
                              </div>
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      ))
                    ) : (
                      <div className="py-6 text-center text-muted-foreground">
                        No notices found in this category.
                      </div>
                    )}
                  </Accordion>
                </CardContent>
              </CardWithStyle>
              
              <CardWithStyle>
                <CardHeader>
                  <CardTitle>School Prospectus</CardTitle>
                  <CardDescription>
                    Download our comprehensive school prospectus for detailed information about our programs, facilities, and admission procedures
                  </CardDescription>
                </CardHeader>
                <CardContent className="flex justify-center">
                  <ButtonWithIcon leftIcon={<Download className="h-4 w-4" />} size="lg">
                    Download Prospectus
                  </ButtonWithIcon>
                </CardContent>
              </CardWithStyle>
            </div>
            
            {/* Calendar Section */}
            <div className="space-y-6">
              <CardWithStyle>
                <CardHeader>
                  <CardTitle>Academic Calendar</CardTitle>
                  <CardDescription>View upcoming events and important dates</CardDescription>
                </CardHeader>
                <CardContent className="p-0">
                  <div className="border-t pt-4">
                    <Calendar
                      mode="single"
                      selected={date}
                      onSelect={setDate}
                      className="rounded-md"
                      modifiers={{
                        event: day => hasEvent(day)
                      }}
                      modifiersClassNames={{
                        event: "border-2 border-primary"
                      }}
                    />
                  </div>
                </CardContent>
              </CardWithStyle>
              
              {date && (
                <CardWithStyle>
                  <CardHeader>
                    <CardTitle>Events on {format(date, "MMMM d, yyyy")}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    {eventsForSelectedDate.length > 0 ? (
                      <div className="space-y-4">
                        {eventsForSelectedDate.map(event => (
                          <div key={event.id} className="flex items-start gap-3 border-b border-border pb-4 last:border-0 last:pb-0">
                            <div className="p-2 bg-primary/10 rounded-full">
                              <CalendarIcon className="h-4 w-4 text-primary" />
                            </div>
                            <div>
                              <h3 className="font-medium">{event.title}</h3>
                              <p className="text-sm text-muted-foreground capitalize">{event.category}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="py-4 text-center text-muted-foreground">
                        No events scheduled for this date.
                      </div>
                    )}
                  </CardContent>
                </CardWithStyle>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
