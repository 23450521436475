
import React, { useState, useEffect, ReactNode } from 'react';
import { toast } from 'sonner';
import { InventoryContext } from './InventoryContext';
import { InventoryItem, AddInventoryItemInput, InventoryChangeRecord } from '@/types/inventory';
import { calculateStatus, sampleInventoryItems } from '@/utils/inventoryUtils';

interface InventoryProviderProps {
  children: ReactNode;
}

export const InventoryProvider = ({ children }: InventoryProviderProps) => {
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  const [changeRecords, setChangeRecords] = useState<InventoryChangeRecord[]>([]);
  const [isInventoryUser, setIsInventoryUser] = useState(false);

  useEffect(() => {
    // Check if user is logged in to inventory system
    const inventoryUser = localStorage.getItem('inventory_user');
    setIsInventoryUser(!!inventoryUser);

    // Load inventory items from localStorage or use sample data
    const storedItems = localStorage.getItem('inventory_items');
    if (storedItems) {
      setInventoryItems(JSON.parse(storedItems));
    } else {
      setInventoryItems(sampleInventoryItems);
      localStorage.setItem('inventory_items', JSON.stringify(sampleInventoryItems));
    }

    // Load inventory change records from localStorage
    const storedChangeRecords = localStorage.getItem('inventory_change_records');
    if (storedChangeRecords) {
      setChangeRecords(JSON.parse(storedChangeRecords));
    }
  }, []);

  // Save inventory changes to localStorage
  useEffect(() => {
    if (inventoryItems.length > 0) {
      localStorage.setItem('inventory_items', JSON.stringify(inventoryItems));
    }
  }, [inventoryItems]);

  // Save inventory change records to localStorage
  useEffect(() => {
    if (changeRecords.length > 0) {
      localStorage.setItem('inventory_change_records', JSON.stringify(changeRecords));
    }
  }, [changeRecords]);

  // Extract unique categories and locations for filtering
  const categories = Array.from(new Set(inventoryItems.map(item => item.category)));
  const locations = Array.from(new Set(inventoryItems.map(item => item.location)));

  const addItem = (item: AddInventoryItemInput) => {
    const newItem: InventoryItem = {
      ...item,
      id: Date.now().toString(),
      lastUpdated: new Date().toISOString().split('T')[0],
      status: calculateStatus(item.quantity, item.minQuantity)
    };
    
    setInventoryItems(prev => [...prev, newItem]);
    toast.success(`Added ${item.name} to inventory`);
  };

  const updateItem = (id: string, updatedItem: Partial<InventoryItem>, notes?: string) => {
    // Find the item before updating to capture the previous state
    const previousItem = inventoryItems.find(item => item.id === id);
    
    setInventoryItems(prev => 
      prev.map(item => {
        if (item.id === id) {
          const newItem = { 
            ...item, 
            ...updatedItem, 
            lastUpdated: new Date().toISOString().split('T')[0]
          };
          
          // Recalculate status if quantity or minQuantity changed
          if ('quantity' in updatedItem || 'minQuantity' in updatedItem) {
            newItem.status = calculateStatus(
              'quantity' in updatedItem ? updatedItem.quantity! : item.quantity,
              'minQuantity' in updatedItem ? updatedItem.minQuantity! : item.minQuantity
            );
            
            // Create change record if quantity changed
            if ('quantity' in updatedItem && previousItem && updatedItem.quantity !== previousItem.quantity) {
              const previousQuantity = previousItem.quantity;
              const newQuantity = updatedItem.quantity!;
              const changeAmount = newQuantity - previousQuantity;
              
              // Determine change type
              let changeType: 'increase' | 'decrease' | 'set' | 'restock';
              if (changeAmount > 0) {
                changeType = 'increase';
              } else if (changeAmount < 0) {
                changeType = 'decrease';
              } else {
                changeType = 'set';
              }
              
              // Add the change record
              addChangeRecord({
                itemId: id,
                previousQuantity,
                newQuantity,
                changeAmount,
                changeType,
                userId: localStorage.getItem('inventory_user') || null,
                notes: notes || (updatedItem.notes ?? '')
              });
            }
          }
          
          return newItem;
        }
        return item;
      })
    );
    toast.success('Inventory item updated');
  };

  const deleteItem = (id: string) => {
    const itemToDelete = inventoryItems.find(item => item.id === id);
    setInventoryItems(prev => prev.filter(item => item.id !== id));
    if (itemToDelete) {
      toast.success(`Removed ${itemToDelete.name} from inventory`);
    }
  };

  const logoutInventory = () => {
    localStorage.removeItem('inventory_user');
    setIsInventoryUser(false);
    toast.info('Logged out from inventory management');
  };

  const getItemById = (id: string) => {
    return inventoryItems.find(item => item.id === id);
  };

  const getItemHistory = (itemId: string): InventoryChangeRecord[] => {
    return changeRecords.filter(record => record.itemId === itemId)
      .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  };

  const addChangeRecord = (record: Omit<InventoryChangeRecord, 'id' | 'timestamp'>) => {
    const newRecord: InventoryChangeRecord = {
      ...record,
      id: Date.now().toString(),
      timestamp: new Date().toISOString()
    };
    
    setChangeRecords(prev => [...prev, newRecord]);
  };

  return (
    <InventoryContext.Provider value={{
      inventoryItems,
      addItem,
      updateItem,
      deleteItem,
      isInventoryUser,
      logoutInventory,
      categories,
      locations,
      getItemById,
      getItemHistory,
      addChangeRecord
    }}>
      {children}
    </InventoryContext.Provider>
  );
};
