
import React from 'react';
import { motion } from 'framer-motion';

interface LoadingAnimationProps {
  isLoading: boolean;
  onAnimationComplete?: () => void;
}

export const LoadingAnimation = ({ 
  isLoading, 
  onAnimationComplete 
}: LoadingAnimationProps) => {
  if (!isLoading) return null;
  
  return (
    <motion.div
      className="fixed inset-0 z-50 flex items-center justify-center bg-background"
      initial={{ opacity: 1 }}
      animate={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 1.8 }}
      onAnimationComplete={onAnimationComplete}
    >
      <div className="flex flex-col items-center">
        <motion.div
          initial={{ scale: 0.8, opacity: 0, rotate: -10 }}
          animate={{ scale: 1, opacity: 1, rotate: 0 }}
          transition={{ duration: 0.6 }}
          className="mb-4"
        >
          <img 
            src="/lovable-uploads/f6ce99c3-7765-4d7e-8dd3-df7d44a04500.png" 
            alt="St. Thomas Higher Secondary School"
            className="h-24 w-24 object-contain"
            onError={(e) => {
              console.error("Image failed to load");
              e.currentTarget.src = "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTIgNkM4LjY4IDYgNiA4LjY4IDYgMTJDNiAxNS4zMiA4LjY4IDE4IDEyIDE4QzE1LjMyIDE4IDE4IDE1LjMyIDE4IDEyQzE4IDguNjggMTUuMzIgNiAxMiA2Wk0xMiAxNkM5Ljc5IDE2IDggMTQuMjEgOCAxMkM4IDkuNzkgOS43OSA4IDEyIDhDMTQuMjEgOCAxNiA5Ljc5IDE2IDEyQzE2IDE0LjIxIDE0LjIxIDE2IDEyIDE2Wk0xMiAxMEMxMC45IDEwIDEwIDEwLjkgMTAgMTJDMTAgMTMuMSAxMC45IDE0IDEyIDE0QzEzLjEgMTQgMTQgMTMuMSAxNCAxMkMxNCAxMC45IDEzLjEgMTAgMTIgMTBaIiBmaWxsPSIjNjQ2NDY0Ii8+PC9zdmc+";
              e.currentTarget.className = "h-24 w-24 bg-gray-100 p-4";
            }}
          />
        </motion.div>
        
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: 0.6 }}
          className="text-xl font-display mb-6 text-primary"
        >
          St. Thomas Higher Secondary School
        </motion.h1>
        
        <motion.div
          initial={{ width: 0 }}
          animate={{ width: "240px" }}
          transition={{ duration: 1.2, delay: 0.8 }}
          className="h-1 bg-gradient-to-r from-primary via-secondary to-accent rounded-full"
        />
      </div>
    </motion.div>
  );
};
