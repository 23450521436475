
import React from 'react';

export function EligibilityCriteria() {
  return (
    <>
      <p>
        St. Thomas Higher Secondary School admits students based on the following criteria:
      </p>
      <ul className="list-disc list-inside space-y-2">
        <li>For Nursery: Children must be at least 3 years old by 31st March of the academic year.</li>
        <li>For LKG: Children must be at least 4 years old by 31st March of the academic year.</li>
        <li>For UKG: Children must be at least 5 years old by 31st March of the academic year.</li>
        <li>For Class 1 and above: Successful completion of the previous class with satisfactory academic records.</li>
      </ul>
      <p>
        Admission to higher classes is subject to the availability of seats and performance in the entrance test.
      </p>
    </>
  );
}
