
import React, { memo } from 'react';
import { Badge } from '@/components/ui-components/Badge';
import { FeaturedImageGrid } from '@/components/ui-components/FeaturedImageGrid';
import { motion } from 'framer-motion';

interface FeaturedImagesSectionProps {
  featuredImages: {
    src: string;
    alt: string;
    caption?: string;
  }[];
}

// Using memo to prevent unnecessary re-renders
export const FeaturedImagesSection = memo(function FeaturedImagesSection({ featuredImages }: FeaturedImagesSectionProps) {
  return (
    <section className="py-16 md:py-24 bg-gradient-to-r from-blue-50 via-green-50 to-yellow-50 dark:from-slate-900 dark:via-blue-900 dark:to-purple-900">
      <div className="container px-4 md:px-6">
        <motion.div 
          className="flex flex-col items-center text-center space-y-4 mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Badge className="bg-primary text-white font-display px-4 py-1 text-sm">School Life</Badge>
          <h2 className="text-3xl md:text-4xl font-display tracking-tighter">
            Fun Activities at Our School
          </h2>
          <p className="text-muted-foreground md:text-lg max-w-[700px]">
            Take a peek at the fun activities and special events our students enjoy!
          </p>
        </motion.div>
        
        <FeaturedImageGrid images={featuredImages} className="mb-12" />
        
        <div className="flex flex-col md:flex-row items-center justify-center gap-8 mt-8">
          <div className="children-image-frame w-full md:w-1/2 lg:w-1/3">
            <img 
              src="/lovable-uploads/ad53dd61-a7a5-42d3-8f34-b8e18ee136bb.png" 
              alt="Students at St. Thomas School" 
              className="w-full h-64 object-cover rounded-2xl border-4 border-primary"
              loading="lazy"
            />
          </div>
          <div className="children-image-frame w-full md:w-1/2 lg:w-1/3">
            <img 
              src="/lovable-uploads/853d316d-34c9-4526-8383-44f342944db0.png" 
              alt="Cultural performance at school" 
              className="w-full h-64 object-cover rounded-2xl border-4 border-secondary"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  );
});
