
import React, { ReactNode } from 'react';
import { Button, ButtonProps } from '@/components/ui/button';
import { cn } from '@/lib/utils';

interface ButtonWithIconProps extends ButtonProps {
  leftIcon?: ReactNode;
  children: ReactNode;
}

export function ButtonWithIcon({ 
  leftIcon, 
  children, 
  className, 
  asChild = false,
  ...props 
}: ButtonWithIconProps) {
  return (
    <Button className={cn("flex items-center gap-2", className)} asChild={asChild} {...props}>
      {asChild ? (
        // When asChild is true, we need to clone the child element and add our icon
        React.Children.only(children as React.ReactElement) && 
        React.cloneElement(children as React.ReactElement, {
          className: cn("flex items-center gap-2", (children as React.ReactElement).props.className)
        }, (
          <>
            {leftIcon && <span className="shrink-0">{leftIcon}</span>}
            <span>{(children as React.ReactElement).props.children}</span>
          </>
        ))
      ) : (
        // Normal rendering when asChild is false
        <>
          {leftIcon && <span className="shrink-0">{leftIcon}</span>}
          <span>{children}</span>
        </>
      )}
    </Button>
  );
}
