
import { getPublicImageUrl } from '@/integrations/supabase/client';

// Cache image URLs to avoid duplicate calls
const imageUrlCache: Record<string, string> = {};

// Function to get cached image URL
const getCachedImageUrl = (filename: string): string => {
  if (!imageUrlCache[filename]) {
    imageUrlCache[filename] = getPublicImageUrl(filename);
  }
  return imageUrlCache[filename];
};

// These are the filenames of images in your Supabase 'website-images' bucket
// When you upload images to your bucket, use these filenames or update this list
export const schoolImages = [
  {
    src: getCachedImageUrl('students-and-teachers.png'),
    alt: 'Students and teachers at St. Thomas School',
    caption: 'Our wonderful student community'
  },
  {
    src: getCachedImageUrl('planting-activity.png'),
    alt: 'Students planting activity',
    caption: 'Learning through gardening activities'
  },
  {
    src: getCachedImageUrl('cultural-performance.png'),
    alt: 'Kids Day cultural performance',
    caption: 'Annual cultural celebrations'
  },
  {
    src: getCachedImageUrl('traditional-dress.png'),
    alt: 'Student in traditional dress',
    caption: 'Celebrating our heritage'
  }
];

export const featuredImages = [
  {
    src: getCachedImageUrl('students-and-teachers.png'),
    alt: 'Students at St. Thomas School',
    caption: 'Growing Knowledge Together'
  },
  {
    src: getCachedImageUrl('planting-activity.png'),
    alt: 'Students planting activity',
    caption: 'Environmental Education & Sustainability'
  }
];
