
import React, { useState } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { VideoIcon, ExternalLink, Calendar } from 'lucide-react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { AspectRatio } from '@/components/ui/aspect-ratio';

// Mock data - replace with API call to fetch videos from database
const mockVideos = [
  {
    id: '1',
    title: 'Annual Sports Day Highlights',
    description: 'Watch the exciting moments from our Annual Sports Day event',
    thumbnailUrl: '/lovable-uploads/c3e948aa-297f-43b3-a96f-1f456799fd18.png',
    videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
    date: '2023-12-10',
    duration: '5:30'
  },
  {
    id: '2',
    title: 'Science Exhibition Projects',
    description: 'Our students showcase their innovative science projects',
    thumbnailUrl: '/lovable-uploads/f8f39796-2b5f-417d-9616-5401f91c6d3c.png',
    videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
    date: '2023-11-15',
    duration: '8:45'
  },
  {
    id: '3',
    title: 'Independence Day Celebration',
    description: 'Special performances on Independence Day',
    thumbnailUrl: '/lovable-uploads/c3e948aa-297f-43b3-a96f-1f456799fd18.png',
    videoUrl: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
    date: '2023-08-15',
    duration: '12:20'
  }
];

export function VideoGallery() {
  const [selectedVideo, setSelectedVideo] = useState<typeof mockVideos[0] | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  
  const handleVideoClick = (videoId: string) => {
    const video = mockVideos.find(v => v.id === videoId);
    if (video) {
      setSelectedVideo(video);
      setDialogOpen(true);
    }
  };

  const handleOpenExternal = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {mockVideos.map((video) => (
          <Card key={video.id} className="overflow-hidden hover:shadow-lg transition-shadow">
            <div 
              className="relative h-48 cursor-pointer" 
              onClick={() => handleVideoClick(video.id)}
            >
              {video.thumbnailUrl ? (
                <img 
                  src={video.thumbnailUrl} 
                  alt={video.title} 
                  className="w-full h-full object-cover"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center bg-muted">
                  <VideoIcon className="w-12 h-12 text-muted-foreground" />
                </div>
              )}
              <div className="absolute inset-0 bg-black/40 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
                <VideoIcon className="w-12 h-12 text-white" />
              </div>
            </div>
            <CardContent className="p-4">
              <h3 className="text-lg font-medium mb-1">{video.title}</h3>
              <p className="text-sm text-muted-foreground mb-2">{video.description}</p>
              <div className="flex justify-between items-center mt-2">
                <div className="flex items-center gap-1">
                  <Calendar className="h-3 w-3 text-muted-foreground" />
                  <span className="text-xs text-muted-foreground">{video.date}</span>
                </div>
                <span className="text-xs font-medium">{video.duration}</span>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
      
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="max-w-4xl p-6">
          {selectedVideo && (
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-bold">{selectedVideo.title}</h2>
                <Button 
                  variant="outline" 
                  size="sm" 
                  className="gap-1"
                  onClick={() => handleOpenExternal(selectedVideo.videoUrl)}
                >
                  <ExternalLink className="h-4 w-4" />
                  Open in YouTube
                </Button>
              </div>
              
              <AspectRatio ratio={16 / 9}>
                <iframe
                  src={selectedVideo.videoUrl}
                  title={selectedVideo.title}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-full h-full rounded-md"
                ></iframe>
              </AspectRatio>
              
              <p className="text-sm text-muted-foreground">{selectedVideo.description}</p>
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-1">
                  <Calendar className="h-4 w-4 text-muted-foreground" />
                  <span className="text-sm">{selectedVideo.date}</span>
                </div>
                <span className="text-sm">Duration: {selectedVideo.duration}</span>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
