
import React from 'react';
import { FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Checkbox } from "@/components/ui/checkbox";
import { UseFormReturn } from "react-hook-form";
import { AdmissionFormData } from "@/types/admissionForm";

interface TermsSectionProps {
  form: UseFormReturn<AdmissionFormData>;
}

export function TermsSection({ form }: TermsSectionProps) {
  return (
    <div className="flex items-start space-x-2">
      <FormField
        control={form.control}
        name="termsAccepted"
        render={({ field }) => (
          <div className="flex items-start space-x-2">
            <Checkbox
              checked={field.value}
              onCheckedChange={field.onChange}
              id="termsAccepted"
            />
            <div className="space-y-1 leading-none">
              <label
                htmlFor="termsAccepted"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                I confirm that all the information provided is accurate and complete. I understand that any false information
                may result in the cancellation of the application or admission.
              </label>
              {form.formState.errors.termsAccepted && (
                <p className="text-sm font-medium text-destructive">
                  {form.formState.errors.termsAccepted.message}
                </p>
              )}
            </div>
          </div>
        )}
      />
    </div>
  );
}
