
import React from 'react';
import { FileText, Sparkles, Star } from 'lucide-react';
import { FunButton } from '@/components/ui-components/FunButton';

export function AdmissionCTA() {
  return (
    <div className="flex flex-col items-center justify-center p-8 bg-gradient-to-r from-blue-50 to-green-50 dark:from-blue-900/30 dark:to-green-900/30 rounded-3xl border-4 border-primary shadow-xl text-center relative overflow-hidden">
      <div className="absolute -top-10 -right-10 text-yellow-300 animate-spin-slow opacity-20">
        <Star className="h-32 w-32" />
      </div>
      <div className="absolute bottom-5 left-5 text-primary animate-pulse opacity-20">
        <Star className="h-16 w-16" />
      </div>
      
      <h2 className="text-3xl font-display text-primary mb-4 relative">
        Ready to Join Our School Family?
        <span className="absolute -top-5 -right-5 text-accent wiggle">
          <Sparkles className="h-6 w-6" />
        </span>
      </h2>
      
      <p className="text-muted-foreground mb-2 max-w-xl text-lg">
        Complete our fun application form to start your amazing journey at St. Thomas Higher Secondary School!
      </p>
      
      <p className="text-sm text-amber-600 dark:text-amber-400 mb-6 max-w-xl font-medium">
        Please keep scanned copies of Birth Certificate and Aadhaar Card ready, as they are required for submission.
      </p>
      
      <FunButton
        href="/admission-form"
        color="primary"
        size="lg"
        animationEffect="bounce"
        className="gap-2 px-10"
      >
        <FileText className="h-5 w-5" />
        Apply Now!
      </FunButton>
    </div>
  );
}
