
import React, { useState, useEffect, useMemo } from 'react';
import { ChevronLeft, ChevronRight, ImageIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface ImageCarouselProps {
  images: {
    src: string;
    alt: string;
    caption?: string;
  }[];
  autoPlay?: boolean;
  interval?: number;
  className?: string;
}

export function ImageCarousel({ 
  images, 
  autoPlay = true, 
  interval = 5000,
  className = "" 
}: ImageCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState<boolean[]>(new Array(images.length).fill(false));
  const [imageErrors, setImageErrors] = useState<boolean[]>(new Array(images.length).fill(false));
  const [isVisible, setIsVisible] = useState(false);

  // Memoize images array to prevent unnecessary re-renders
  const memoizedImages = useMemo(() => images, [images]);

  useEffect(() => {
    // Use Intersection Observer to detect when carousel is visible
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1
      }
    );

    const carouselElement = document.getElementById('carousel-container');
    if (carouselElement) {
      observer.observe(carouselElement);
    }

    return () => {
      if (carouselElement) {
        observer.unobserve(carouselElement);
      }
    };
  }, []);

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % memoizedImages.length);
  };

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + memoizedImages.length) % memoizedImages.length);
  };

  const goToIndex = (index: number) => {
    setCurrentIndex(index);
  };

  const handleImageLoad = (index: number) => {
    setImagesLoaded(prev => {
      const newState = [...prev];
      newState[index] = true;
      return newState;
    });
  };

  const handleImageError = (index: number) => {
    console.error(`Error loading image ${index}:`, memoizedImages[index].src);
    setImageErrors(prev => {
      const newState = [...prev];
      newState[index] = true;
      return newState;
    });
  };

  useEffect(() => {
    if (autoPlay && memoizedImages.length > 0 && isVisible) {
      const timer = setTimeout(goToNext, interval);
      return () => clearTimeout(timer);
    }
  }, [currentIndex, autoPlay, interval, memoizedImages.length, isVisible]);

  // Don't render if there are no images
  if (!memoizedImages || memoizedImages.length === 0) {
    return (
      <div className={`relative overflow-hidden rounded-2xl flex items-center justify-center bg-muted ${className}`}>
        <div className="flex flex-col items-center justify-center p-8">
          <ImageIcon className="h-16 w-16 text-muted-foreground mb-2" />
          <p className="text-muted-foreground">No images available</p>
        </div>
      </div>
    );
  }

  return (
    <div id="carousel-container" className={`relative overflow-hidden rounded-2xl ${className}`}>
      <div className="relative h-full">
        {memoizedImages.map((image, index) => {
          // Only render images that are currently visible or adjacent (prev/next)
          const shouldRender = 
            index === currentIndex || 
            index === (currentIndex + 1) % memoizedImages.length ||
            index === (currentIndex - 1 + memoizedImages.length) % memoizedImages.length;
            
          return shouldRender ? (
            <div
              key={index}
              className={`absolute inset-0 transition-opacity duration-1000 ${
                index === currentIndex ? 'opacity-100 z-10' : 'opacity-0 z-0'
              }`}
            >
              {(!imagesLoaded[index] || imageErrors[index]) && (
                <div className="absolute inset-0 flex flex-col items-center justify-center bg-muted">
                  <ImageIcon className="h-16 w-16 text-muted-foreground animate-pulse mb-2" />
                  {imageErrors[index] && <p className="text-sm text-destructive">Failed to load image</p>}
                </div>
              )}
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-full object-cover"
                loading={index === 0 ? "eager" : "lazy"}
                onLoad={() => handleImageLoad(index)}
                onError={() => handleImageError(index)}
                style={{ opacity: imagesLoaded[index] && !imageErrors[index] ? 1 : 0 }}
              />
              {image.caption && (
                <div className="absolute bottom-0 left-0 right-0 bg-black/50 text-white p-4 text-center font-display">
                  {image.caption}
                </div>
              )}
            </div>
          ) : null;
        })}
      </div>

      {memoizedImages.length > 1 && (
        <>
          <Button
            variant="ghost"
            size="icon"
            className="absolute left-2 top-1/2 -translate-y-1/2 z-20 bg-white/80 hover:bg-white text-black rounded-full p-2"
            onClick={goToPrevious}
          >
            <ChevronLeft size={24} />
          </Button>

          <Button
            variant="ghost"
            size="icon"
            className="absolute right-2 top-1/2 -translate-y-1/2 z-20 bg-white/80 hover:bg-white text-black rounded-full p-2"
            onClick={goToNext}
          >
            <ChevronRight size={24} />
          </Button>

          <div className="absolute bottom-4 left-0 right-0 z-20 flex justify-center space-x-2">
            {memoizedImages.map((_, index) => (
              <button
                key={index}
                className={`w-3 h-3 rounded-full transition-all ${
                  index === currentIndex ? 'bg-white scale-125' : 'bg-white/50'
                }`}
                onClick={() => goToIndex(index)}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
